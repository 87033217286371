<template>
	<router-view />
	<LoadingWidget />
	<vue-basic-alert ref="alert" />
</template>

<script>
import LoadingWidget from "./components/LoadingWidget.vue"
import CookieService from "./services/Cookie.service"
import userService from "./services/User.service"
import userController from "./controllers/User.controller"
import VueBasicAlert from "vue-basic-alert"

export default {
	components: {
		LoadingWidget,
		VueBasicAlert
	},
	methods: {
		async init() {
			const token = CookieService.getCookie("token")

			if (token) {
				this.$store.dispatch("setToken", token)

				const user = CookieService.getCookie("user")

				if (user === null || user === "null") {
					await userController.logout()
					return
				}

				if (user) {
					this.$store.dispatch("setUser", JSON.parse(user))

					const updatedUser = await userService.myProfile()

					CookieService.setCookie(
						"user",
						JSON.stringify(updatedUser.data)
					)
				}

				const product = CookieService.getCookie("product")

				if (product) {
					this.$store.dispatch("setProduct", JSON.parse(product))
				}
			}
		}
	},
	mounted() {
		this.init()
	}
}
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

body {
	margin: 0;
	padding: 0;
}

nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
</style>
