<template>
	<div class="section__cartao">
		<div class="cartao">
			<img src="@/assets/cartao-frente.png" alt="" />
			<div class="cartao_container">{{ getCardNumber() }}</div>

			<div class="cartao_informacoes">
				<div class="cartao_nome">{{ name === "" ? "NOME" : name }}</div>
				<div class="cartao_data">
					{{
						month === "null"
							? "**"
							: textToMonth(month).toString().padStart(2, "0")
					}}/{{ year === "null" ? "****" : year }}
				</div>
			</div>
		</div>

		<div class="formulario-cartao">
			<div class="field__numero-cartao">
				<input
					type="text"
					placeholder="Número do cartão"
					class="form__numero-cartao"
					name="form__numero-cartao"
					access="false"
					id="form__numero-cartao"
					required="required"
					aria-required="true"
					v-maska
					data-maska="#### #### #### ####"
					v-model="cardNumber"
					:disabled="coupon?.value === 0"
				/>
			</div>
			<div class="field__nome--bandeira">
				<input
					type="text"
					placeholder="Nome do titular"
					class="form__nome-cartao"
					name="form__nome-cartao"
					access="false"
					id="form__nome-cartao"
					required="required"
					aria-required="true"
					v-model="name"
					:disabled="coupon?.value === 0"
				/>

				>
				<!-- <select
					class="form__bandeira"
					required="required"
					aria-required="true"
					v-model="flag"
				>
					<option disabled="null" selected="null" value="null">
						Bandeira &#709; &#10240;
					</option>
					<option id="select">Visa</option>
					<option id="select">Mastercard</option>
					<option id="select">Elo</option>
				</select> -->
			</div>
			<div class="field__mes--ano--cod">
				<select
					class="form__mes"
					name="form__mes"
					id="form__mes"
					required="required"
					aria-required="true"
					v-model="month"
					:disabled="coupon?.value === 0"
				>
					<option disabled="null" selected="null" value="null">
						Mês &#709; &#10240;
					</option>
					<option id="select">Janeiro</option>
					<option id="select">Fevereiro</option>
					<option id="select">Março</option>
					<option id="select">Abril</option>
					<option id="select">Maio</option>
					<option id="select">Junho</option>
					<option id="select">Julho</option>
					<option id="select">Agosto</option>
					<option id="select">Setembro</option>
					<option id="select">Outubro</option>
					<option id="select">Novembro</option>
					<option id="select">Dezembro</option>
				</select>

				<select
					class="form__ano"
					name="form__ano"
					id="form__ano"
					required="required"
					aria-required="true"
					v-model="year"
					:disabled="coupon?.value === 0"
				>
					<option disabled="null" selected="null" value="null">
						Ano &#709; &#10240;
					</option>
					<option id="select">2023</option>
					<option id="select">2024</option>
					<option id="select">2025</option>
					<option id="select">2026</option>
					<option id="select">2027</option>
					<option id="select">2028</option>
					<option id="select">2029</option>
					<option id="select">2030</option>
					<option id="select">2031</option>
					<option id="select">2032</option>
					<option id="select">2033</option>
					<option id="select">2034</option>
					<option id="select">2035</option>
					<option id="select">2036</option>
					<option id="select">2037</option>
					<option id="select">2038</option>
					<option id="select">2039</option>
					<option id="select">2040</option>
				</select>

				<input
					placeholder="Código de segurança"
					class="form__cod-seguranca"
					access="false"
					id="control-917160"
					required="required"
					aria-required="true"
					name="undefined"
					v-maska
					data-maska="###"
					v-model="cvv"
					:disabled="coupon?.value === 0"
				/>
			</div>
		</div>

		<div class="form-text form-text--cartao">
			<h3>Escolha o Parcelamento:</h3>
		</div>

		<div class="field__parcelamento">
			<select
				class="form__parcelamento"
				required="required"
				aria-required="true"
				v-model="installments"
				:disabled="coupon?.value === 0"
			>
				<option disabled="null" selected="true" value="null">
					Parcelamento &#709; &#10240;
				</option>
				<option
					id="select"
					v-for="p in paymentConditions"
					:key="p"
					:value="p.parcela"
				>
					{{ p.parcela }}x {{ moneyMask(p.valor) }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
import productController from "@/controllers/Product.controller"
import cookieService from "@/services/Cookie.service"
import { hideLoading, showLoading } from "@/utils/loading"
import { moneyMask } from "@/utils/moneyMask"

export default {
	data() {
		return {
			cardNumber: "",
			name: "",
			month: "null",
			year: "null",
			/* flag: "null", */
			cvv: "",
			installments: "null",
			paymentConditions: []
		}
	},
	watch: {
		name: function () {
			this.name = this.name.toUpperCase()
		},
		coupon: function () {
			this.getInstallments()
		}
	},
	props: {
		coupon: {
			type: Object
		}
	},

	methods: {
		moneyMask,
		async getInstallments() {
			showLoading()

			const params = {}

			if (this.coupon !== null) {
				params.coupon = this.coupon.code
			}

			const product = await productController.getBySlug(
				cookieService.getCookie("slug"),
				params
			)

			this.paymentConditions = product.payment_conditions

			hideLoading()
		},
		getCardNumber() {
			if (this.cardNumber === "" || this.cardNumber === undefined)
				return "**** **** **** ****"

			const cardNumber = JSON.parse(JSON.stringify(this.cardNumber))
				.replace(/\s/g, "")
				.padEnd(16, "*")

			return (
				cardNumber.substr(0, 4) +
				" " +
				cardNumber.substr(4, 4) +
				" " +
				cardNumber.substr(8, 4) +
				" " +
				cardNumber.substr(12, 4)
			)
		},
		textToMonth(month) {
			switch (month) {
				case "Janeiro":
					return 1
				case "Fevereiro":
					return 2
				case "Março":
					return 3
				case "Abril":
					return 4
				case "Maio":
					return 5
				case "Junho":
					return 6
				case "Julho":
					return 7
				case "Agosto":
					return 8
				case "Setembro":
					return 9
				case "Outubro":
					return 10
				case "Novembro":
					return 11
				case "Dezembro":
					return 12
				default:
					return 1
			}
		},

		validate() {
			if (this.coupon !== null) {
				if (this.coupon.value === 0) {
					return {
						success: true,
						data: {
							formOfPayment: "debit",
							product: this.$store.state.product.slug,
							installments: 1,
							coupon_code: this.coupon.code
						}
					}
				}
			}

			if (this.cardNumber === "") {
				alert("Preencha o número do cartão")
				return {
					success: false
				}
			}

			if (this.cardNumber.replaceAll(" ", "").length < 16) {
				alert("Preencha o número do cartão corretamente")
				return {
					success: false
				}
			}

			if (this.name === "") {
				alert("Preencha o nome do titular")
				return {
					success: false
				}
			}

			/* if (this.flag === "null") {
				alert("Preencha a bandeira do cartão")
				return {
					success: false
				}
			} */

			if (this.month === "null") {
				alert("Preencha o mês de validade")
				return {
					success: false
				}
			}

			if (this.year === "null") {
				alert("Preencha o ano de validade")
				return {
					success: false
				}
			}

			if (this.cvv === "") {
				alert("Preencha o CVV")
				return {
					success: false
				}
			}

			if (this.cvv.length < 3) {
				alert("Preencha o CVV corretamente")
				return {
					success: false
				}
			}

			if (this.installments === "null") {
				alert("Preencha o parcelamento")
				return {
					success: false
				}
			}

			const data = {
				formOfPayment: "credit",
				product: this.$store.state.product.slug,
				installments: this.installments,
				credit_card: {
					cardNumber: this.cardNumber.replaceAll(" ", ""),
					securityCode: this.cvv,
					cardholderName: this.name,
					expirationMonth: this.textToMonth(this.month),
					expirationYear: parseInt(this.year)
				}
			}

			if (this.coupon !== null) {
				data.coupon_code = this.coupon.code
			}

			return {
				success: true,
				data: data
			}
		}
	},
	mounted() {
		this.getInstallments()
	}
}
</script>

<style>
.linha,
.linha img {
	width: 100%;
}

.cartao {
	position: relative;
	display: flex;
	justify-content: center;
}

.cartao_container {
	position: absolute;
	top: 73px;
	width: 259px;
	height: 20px;
	background-color: #00a7ed;
	color: rgb(255, 255, 255);
	font-size: 20px;
}

.cartao_informacoes {
	position: absolute;
	top: 114px;
	width: 259px;
	height: 20px;
	background-color: #00a7ed;
	color: rgb(255, 255, 255);
	font-size: 20px;
	display: flex;
	justify-content: space-between;
}

.cartao_nome {
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.form__numero-cartao,
.form__nome-cartao,
.form__bandeira,
.form__mes,
.form__ano,
.form__cod-seguranca,
.form__parcelamento {
	background: none;
	outline: none;
	border: 0;
	border-bottom: 2px solid #fff;
	color: #fff;
	height: 30px;
	font-weight: 200;
	font-size: 16px;
	margin-top: 15px;
	padding: 5px 0 5px 15px;
	font-family: "Montserrat";
	font-size: 18px;
}

.form__numero-cartao::placeholder,
.form__nome-cartao::placeholder,
.form__bandeira::placeholder,
.form__mes::placeholder,
.form__ano::placeholder,
.form__cod-seguranca::placeholder,
.form__parcelamento::placeholder {
	color: #fff;
}

.form__numero-cartao {
	width: 100%;
}

.form__nome-cartao {
	width: 80%;
}

.form__bandeira {
	width: 25%;
}

.form__mes {
	width: 25%;
}

.form__ano {
	margin-left: 10px;
	width: 25%;
}

.form__cod-seguranca {
	width: 50%;
	margin-left: 10px;
}

.field__parcelamento {
	display: flex;
	width: 100%;
}

.field__numero-cartao {
	width: 100%;
}

.field__nome--bandeira {
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 10px;
}

.field__mes--ano--cod {
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 10px;
}

.form__bandeira,
.form__mes,
.form__ano,
.form__parcelamento {
	appearance: none;
	height: 37px;
}

.form__parcelamento {
	padding-left: 20px;
	width: 100%;
}

.form-outros {
	display: flex;
	flex-direction: column;
}

input[disabled],
select[disabled] {
	opacity: 0.4;
	cursor: not-allowed;
}
</style>
