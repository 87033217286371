<template>
	<div id="login-page" class="login-unselected">
		<div class="login__botao-close">
			<button class="modal__button-close">
				<img src="../assets/close.svg" alt="" />
			</button>
		</div>
		<div class="login__page">
			<h1 class="login__page___titulo">login</h1>
			<div class="login__page___dados">
				<div class="">
					<label for="undefined" class="formbuilder-text-label"
						><span class="formbuilder-required"></span
					></label>
					<input
						type="email"
						placeholder="E-mail"
						class="login__page___email"
						access="false"
						id="control-9242091"
						required="required"
						aria-required="true"
						name="undefined"
						v-model="email"
					/>
				</div>
				<div class="">
					<label for="undefined" class="formbuilder-text-label"
						><span class="formbuilder-required"></span
					></label>
					<input
						type="password"
						placeholder="Senha"
						class="login__page___senha"
						access="false"
						id="control-3450557"
						required="required"
						aria-required="true"
						name="undefined"
						v-model="password"
					/>
				</div>
			</div>
			<a href="#" class="login__page___esqueci"
				><p class="login__page___esqueci" @click="recoveryPassword">
					Esqueci minha senha
				</p></a
			>
			<!-- <div class="login__page___botao">
				<div
					class="formbuilder-checkbox-group form-group field-login__page___botao"
				>
					<label
						for="login__page___botao"
						class="formbuilder-checkbox-group-label"
						>Mantenha conectado</label
					>
					<div class="checkbox-group">
						<div class="formbuilder-checkbox">
							<label
								for="login__page___botao-0"
								class="kc-toggle"
							>
								<input
									class="login__page___botao"
									name="login__page___botao[]"
									access="false"
									id="login__page___botao-0"
									type="checkbox"
									checked="checked" /><span></span
							></label>
						</div>
					</div>
				</div>
			</div> -->
			<div class="field-login__page___botao-entrar">
				<div class="formbuilder-button form-group">
					<button
						type="button"
						class="btn-primary login__page___botao-entrar btn"
						name="login__page___botao"
						access="false"
						style="primary"
						id="login__page___botao"
						@click="login"
					>
						ENTRAR
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import userController from "@/controllers/User.controller"
import cookieService from "@/services/Cookie.service"
import userInterestController from "@/controllers/UserInterest.controller"

export default {
	data() {
		return {
			email: "",
			password: ""
		}
	},
	methods: {
		async login() {
			if (this.email === "") {
				alert("Preencha o campo de e-mail")
				return
			}
			// Expressão regular para validar o formato do e-mail
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

			if (!emailRegex.test(this.email)) {
				alert("Digite um endereço de e-mail válido")
				return
			}

			if (this.password === "") {
				alert("Preencha o campo de senha")
				return
			}

			const response = await userController.auth({
				email: this.email,
				password: this.password
			})

			if (response !== null) {
				cookieService.setCookie("token", response.token, 1)
				this.$store.dispatch("setToken", response.token)

				const element = document.getElementById("login-page")
				element.classList.add("login-unselected")
				element.classList.remove("login-selected")

				if (this.$store.state.token && this.$store.state.product) {
					await userInterestController.registerInterest({
						product: this.$store.state.product.id
					})
				}
			}
		},
		recoveryPassword() {
			this.$router.push("recuperar-senha")

			const element = document.getElementById("login-page")
			element.classList.add("login-unselected")
			element.classList.remove("login-selected")
		}
	}
}
</script>

<style>
.login__page___email::placeholder,
.login__page___senha::placeholder {
	color: #fff;
}

.login__page___email,
.login__page___senha {
	background: none;
	outline: none;
	border: 0;
	border-bottom: 2px solid #fff;
	color: #fff;
	height: 30px;
	font-weight: 200;
	font-size: 16px;
	padding: 0 0 5px 7px;
}

.login-selected {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	backdrop-filter: blur(3px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-unselected {
	display: none;
}

.login__page {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
	background-color: #00449d;
	width: 400px;
	height: auto;
	border-radius: 10px;
	box-shadow: 5px 7px 20px rgba(0, 0, 0, 0.3);
	color: #fff;
}

.login__botao-close {
	position: absolute;
	transform: translate(170px, -135px);
}

.login__page___titulo {
	font-family: "Montserrat", sans-serif;
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
	color: #00a7ed;
	letter-spacing: 8px;
}

.login__page___dados {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 25px 0 5px 0;
}

.login__page___email,
.login__page___senha {
	font-family: "Montserrat", sans-serif;
	width: 80%;
	margin-top: 15px;
}

.login__page___botao {
	display: flex;
	flex-direction: row;
	width: 90%;
	margin-bottom: 10px;
}

.field-login__page___botao {
	display: flex;
	flex-direction: row-reverse;
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-weight: 200;
	letter-spacing: 1.2px;
	color: #fff;
}

.field-login__page___botao-entrar {
	width: 100%;
}

.login__page___botao-entrar {
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 2px;
	color: #fff;
	background-color: #00a7ed;
	border-radius: 8px;
	margin-bottom: 15px;
	width: 90%;
	height: 40px;
	border: 0;
	cursor: pointer;
}

.login__page___esqueci {
	display: flex;
	width: 80%;
	margin-top: 0;
	font-family: "Montserrat", sans-serif;
	font-size: 12px;
	font-weight: 200;
	text-decoration: none;
	color: #fff;
}
</style>
