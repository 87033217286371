<template>
	<div class="formulario">
		<div class="linha">
			<img src="../../../assets/Linha.png" alt="" />
		</div>
		<div class="form-text">
			<h2>dados pessoais</h2>
		</div>

		<div class="dados">
			<img src="../../../assets/person.svg" alt="" />
			<h1 class="dados__nome">{{ this.$store.state.user.name }}</h1>
			<h3 class="dados__email">{{ this.$store.state.user.email }}</h3>
			<h3 class="dados__telefone">
				{{ this.$store.getters["getPhone"] }}
			</h3>
		</div>
		<div class="trocar-user" @click="swapUser">
			<h2>
				Não é você?
				<a href="#" class="trocar-user__btn">trocar de usuário</a>
			</h2>
		</div>

		<div class="field-btn">
			<button
				type="submit"
				class="form__btn btn-primary btn"
				name="btn"
				access="false"
				style="primary"
				id="btn"
				@click="nextStep"
			>
				Próximo Passo&#10240;
				<img
					src="../../../assets/arrow_forward.svg"
					alt=""
					class="form__btn___img"
				/>
			</button>
		</div>
	</div>
</template>

<script>
import userController from "@/controllers/User.controller"
import cookieService from "@/services/Cookie.service"

export default {
	methods: {
		getPhone() {
			try {
				const user = this.$store.state.user

				if (user.userInfomartions.phone) {
					const prefix = user.userInfomartions.phone_prefix
					const phone = user.userInfomartions.phone
					return `(${prefix}) ${phone}`
				}
			} catch (error) {
				return "N/A"
			}
		},
		nextStep() {
			this.$router.push("/pagamento")
		},
		swapUser() {
			userController.logout()
		}
	},
	async mounted() {
		const user = await userController.myProfile()
		this.$store.dispatch("setUser", user)
		cookieService.setCookie("user", JSON.stringify(user))
	}
}
</script>

<style>
.trocar-user {
	width: 100%;
	justify-content: center;
	color: #fff;
	text-transform: uppercase;
}

.trocar-user__btn {
	text-decoration: none;
	color: #00a7ed;
}

.trocar-user__btn:hover {
	text-decoration: underline;
}
</style>
