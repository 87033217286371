import { exceptions } from "@/api/exceptions"

import userInterestService from "@/services/UserInterest.service"

class UserInterestController {
	async registerInterest(data) {
		try {
			const response = await userInterestService.registerInterest(data)

			if (response !== null) {
				if (response.status === 201) {
					return response.data
				} else {
					alert("Erro ao salvar")
				}
			}
		} catch (error) {
			alert(exceptions(error))
		}

		return null
	}
}

export default new UserInterestController()
