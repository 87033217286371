<template>
	<link
		rel="stylesheet"
		href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
	/>
	<div class="page">
		<header>
			<img src="@/assets/Logo_gsj_grande.png" alt="" />
		</header>

		<main class="content">
			<div class="card">
				<p class="title">Redefina sua senha</p>
				<div class="card__content">
					<div class="input-redifinition">
						<input
							:type="input_type_password"
							class="input-email"
							placeholder="senha"
							v-model="password"
						/>

						<button
							class="show-password"
							:v-model="show_password"
							@click="showPassword"
						>
							<span class="material-symbols-outlined">
								{{
									show_password
										? "visibility"
										: "visibility_off"
								}}
							</span>
						</button>
					</div>
					<div class="input-redifinition">
						<input
							:type="input_type_password_confirmation"
							class="input-email"
							placeholder="confirme sua senha"
							v-model="confirmPassword"
						/>

						<button
							class="show-password"
							:v-model="show_confirm_password"
							@click="showPasswordConfirmation"
						>
							<span class="material-symbols-outlined">
								{{
									show_confirm_password
										? "visibility"
										: "visibility_off"
								}}
							</span>
						</button>
					</div>
					<button class="send" @click="resetPassword">
						Redefinir senha
					</button>
				</div>
				<p class="prev" @click="backLogin">Voltar para o Login</p>
			</div>
		</main>

		<footer>
			<FooterPattern />
		</footer>
	</div>
</template>

<script>
import FooterPattern from "@/components/FooterPattern.vue"
import userController from "@/controllers/User.controller"
import { hideLoading, showLoading } from "@/utils/loading"

export default {
	components: {
		FooterPattern
	},
	data() {
		return {
			token: this.$router.currentRoute.value.params.token,
			password: "",
			show_password: false,
			input_type_password: "password",
			confirmPassword: "",
			input_type_password_confirmation: "password",
			show_confirm_password: false
		}
	},
	watch: {
		password() {
			if (this.password.length > 0) {
				this.visibility = "visibility"
			} else {
				this.visibility = "visibility_off"
			}
		}
	},
	methods: {
		showPassword() {
			this.show_password = !this.show_password
			if (this.show_password) {
				this.input_type_password = "text"
			} else {
				this.input_type_password = "password"
			}
		},
		showPasswordConfirmation() {
			this.show_confirm_password = !this.show_confirm_password
			if (this.show_confirm_password) {
				this.input_type_password_confirmation = "text"
			} else {
				this.input_type_password_confirmation = "password"
			}
		},
		backLogin() {
			this.$router.push("/")
		},
		async resetPassword() {
			if (this.password === "") {
				alert("Coloque sua nova senha")
				return
			}

			if (this.confirmPassword === "") {
				alert("Confirme sua nova senha")
				return
			}

			if (this.password !== this.confirmPassword) {
				alert("As senhas não são iguais")
				return
			}

			if (this.password.length < 6 || this.confirmPassword.length < 6) {
				alert("A senha deve ter no mínimo 6 caracteres")
				return
			}

			showLoading()

			const response = await userController.passwordReset({
				password: this.password.trim(),
				confirmPassword: this.confirmPassword.trim(),
				token: this.token
			})

			hideLoading()

			if (response && response.status === 200) {
				this.$router.push("/redefinicao-senha/sucesso")
			}
		}
	}
}
</script>

<style scoped>
header {
	margin: 80px auto;
}

p {
	margin: 0;
	padding: 0;
}

.page {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
}

.content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.card {
	background: #00449d;
	box-shadow: 5px 7px 20px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	width: 485px;
	height: 280px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.card__content {
	width: 100%;
	gap: 20px;
	flex-direction: column;
	display: flex;
	align-items: center;
}

button.send {
	width: 85%;
	height: 40px;
	background: #00a7ed;
	border-radius: 8px;
	border: none;
	color: white;
	font-family: "Santral-Bold";
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	cursor: pointer;
}

.title {
	font-family: "Santral-Bold";
	letter-spacing: 0.5em;
	text-transform: uppercase;
	color: #00a7ed;
	font-size: 16px;
	line-height: 19px;
}

.description {
	font-family: "Santral-Bold";
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.08em;
	color: #ffffff;
}

.prev {
	font-family: "Santral";
	text-align: left;
	color: #ffffff;
	font-weight: 200;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.08em;
	margin: 0 20px;
	cursor: pointer;
	width: 80%;
}
.input-email {
	width: 80%;
	height: 35px;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid white;
	color: white;
	font-family: "Santral";
	font-size: 18px;
}

.input-email:focus {
	outline: none;
}

.input-email::placeholder {
	color: white;
	font-family: "Santral";
	font-size: 18px;
}

@media only screen and (max-width: 769px) {
	.card {
		margin: 0 25px;
	}
}

@media only screen and (max-width: 480px) {
	.card p {
		font-size: 14px;
	}
}

.show-password {
	background: none;
	border: none;
	color: white;
	font-family: "Santral";
	font-size: 18px;
	cursor: pointer;
	position: relative;
	margin-top: 5px;
	margin-left: 5px;
	font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.input-redifinition {
	position: relative;
	width: 92%;
}
</style>
