<template>
	<div class="page">
		<header>
			<img src="@/assets/Logo_gsj_grande.png" alt="" />
		</header>

		<main class="content">
			<div class="card">
				<p class="title">Redefina sua senha</p>
				<p class="description">Sua senha foi redefinida com sucesso!</p>
				<p class="prev" @click="backLogin">Voltar para o Login</p>
			</div>
		</main>

		<footer>
			<FooterPattern />
		</footer>
	</div>
</template>

<script>
import FooterPattern from "@/components/FooterPattern.vue"

export default {
	components: {
		FooterPattern
	},
	methods: {
		backLogin() {
			this.$router.push("/carrinho")
		}
	}
}
</script>

<style scoped>
header {
	margin: 80px auto;
}

p {
	margin: 0;
	padding: 0;
}

.page {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
}

.content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.card {
	background: #00449d;
	box-shadow: 5px 7px 20px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	width: 485px;
	height: 280px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.title {
	font-family: "Santral-Bold";
	letter-spacing: 0.5em;
	text-transform: uppercase;
	color: #00a7ed;
	font-size: 16px;
	line-height: 19px;
}

.description {
	font-family: "Santral-Bold";
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.08em;
	color: #ffffff;
}

.prev {
	font-family: "Santral";
	text-align: left;
	color: #ffffff;
	font-weight: 200;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.08em;
	margin: 0 20px;
	cursor: pointer;
}

@media only screen and (max-width: 769px) {
	.card {
		margin: 0 25px;
	}
}

@media only screen and (max-width: 480px) {
	.card p {
		font-size: 14px;
		letter-spacing: 2px;
	}
}
</style>
