export const moneyMask = (value) => {
	const options = { minimumFractionDigits: 2 }
	let result = new Intl.NumberFormat("pt-BR", options).format(
		parseFloat(value).toFixed(2)
	)

	if (result === "NaN") {
		result = 0
	}

	return `R$ ${result}`
}
