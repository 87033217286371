<template>
	<div v-if="this.$store.state.token !== null" class="menu">
		<p class="menu__item" @click="to('perfil')">Minha Conta</p>
		<p class="menu__item" @click="logout">Sair</p>
	</div>
</template>

<script>
import userController from "@/controllers/User.controller"

export default {
	methods: {
		async logout() {
			await userController.logout()
			this.openMenu()

			this.$router.push("/")
		},
		to(path) {
			this.$router.push(path)
			this.openMenu()
		},
		openMenu() {
			document
				.querySelector(".curso__header___menu")
				.classList.toggle("active")
			document.querySelector(".botao-menu").classList.toggle("active")
			document.querySelector(".menu_image").classList.toggle("active")
			document.querySelector(".menu").classList.toggle("active")

			const size = document
				.querySelector(".curso__header___menu")
				.getBoundingClientRect()

			if (size.height > 0) {
				document.querySelector(".menu").style.top = `${size.height}px`
			}
		}
	}
}
</script>

<style>
.menu {
	background: rgba(0, 68, 157, 1);
	border-width: 0px 0px 2px 2px;
	border-style: solid;
	border-color: #ffffff;
	border-radius: 0px 0px 0px 16px;
	top: 167px;
	position: absolute;

	width: 300px;
	z-index: 1;
	right: 0;
	visibility: hidden;
}

.menu.active {
	visibility: visible;
}

.menu__item {
	color: #ffffff;
	font-family: "Santral";
	font-style: normal;
	font-weight: 200;
	font-size: 24px;
	line-height: 28px;
	cursor: pointer;
	text-align: left;
	margin-left: 25px;
}
</style>
