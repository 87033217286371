<template>
	<div class="page">
		<header>
			<img src="@/assets/Logo_gsj_grande.png" alt="" />
		</header>

		<main class="content">
			<div class="card">
				<div class="login__page___dados">
					<div class="">
						<label for="undefined" class="formbuilder-text-label"
							><span class="formbuilder-required"></span
						></label>
						<input
							type="email"
							placeholder="E-mail"
							class="login__page___email"
							access="false"
							id="control-9242091"
							required="required"
							aria-required="true"
							name="undefined"
							v-model="email"
						/>
					</div>
					<div class="">
						<label for="undefined" class="formbuilder-text-label"
							><span class="formbuilder-required"></span
						></label>
						<input
							type="password"
							placeholder="Senha"
							class="login__page___senha"
							access="false"
							id="control-3450557"
							required="required"
							aria-required="true"
							name="undefined"
							v-model="password"
						/>
					</div>
				</div>
				<a href="#" class="login__page___esqueci"
					><p class="login__page___esqueci" @click="recoveryPassword">
						Esqueci minha senha
					</p></a
				>
				<div class="field-login__page___botao-entrar">
					<div class="formbuilder-button form-group">
						<button
							type="button"
							class="btn-primary login__page___botao-entrar btn"
							name="login__page___botao"
							access="false"
							style="primary"
							id="login__page___botao"
							@click="login"
						>
							ENTRAR
						</button>
					</div>
				</div>
			</div>
		</main>

		<footer>
			<FooterPattern />
		</footer>
	</div>
</template>

<script>
import FooterPattern from "@/components/FooterPattern.vue"
import userController from "@/controllers/User.controller"
import cookieService from "@/services/Cookie.service"

export default {
	components: {
		FooterPattern
	},
	data() {
		return {
			email: "",
			password: ""
		}
	},
	methods: {
		async login() {
			if (this.email === "") {
				alert("Preencha o campo de e-mail")
				return
			}
			// Expressão regular para validar o formato do e-mail
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

			if (!emailRegex.test(this.email)) {
				alert("Digite um endereço de e-mail válido")
				return
			}

			if (this.password === "") {
				alert("Preencha o campo de senha")
				return
			}

			const response = await userController.auth({
				email: this.email,
				password: this.password
			})

			if (response !== null) {
				cookieService.setCookie("token", response.token, 1)
				this.$store.dispatch("setToken", response.token)

				const user = await userController.myProfile()
				this.$store.dispatch("setUser", user)
				cookieService.setCookie("user", JSON.stringify(user))

				this.$router.push("/perfil")
			}
		},
		recoveryPassword() {
			this.$router.push("recuperar-senha")

			const element = document.getElementById("login-page")
			element.classList.add("login-unselected")
			element.classList.remove("login-selected")
		}
	}
}
</script>

<style scoped>
header {
	margin: 80px auto;
}

p {
	margin: 0;
	padding: 0;
}

.page {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
}

.content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.card {
	background: #00449d;
	box-shadow: 5px 7px 20px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	width: 485px;
	height: 280px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.card__content {
	width: 100%;
	gap: 20px;
	flex-direction: column;
	display: flex;
	align-items: center;
}

button {
	width: 85%;
	height: 40px;
	background: #00a7ed;
	border-radius: 8px;
	border: none;
	color: white;
	font-family: "Santral-Bold";
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	cursor: pointer;
}

.title {
	font-family: "Santral-Bold";
	letter-spacing: 0.5em;
	text-transform: uppercase;
	color: #00a7ed;
	font-size: 16px;
	line-height: 19px;
	margin-top: 20px;
}

.description {
	font-family: "Santral-Bold";
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.08em;
	color: #ffffff;
}

.prev {
	font-family: "Santral";
	text-align: left;
	color: #ffffff;
	font-weight: 200;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.08em;
	margin: 0 20px;
	cursor: pointer;
	width: 80%;
}
.input-email {
	width: 80%;
	height: 35px;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid white;
	color: white;
	font-family: "Santral";
	font-size: 18px;
}

.input-email:focus {
	outline: none;
}

.input-email::placeholder {
	color: white;
	font-family: "Santral";
	font-size: 18px;
}

@media only screen and (max-width: 769px) {
	.card {
		margin: 0 25px;
	}
}

@media only screen and (max-width: 480px) {
	.card p {
		font-size: 14px;
	}
}
</style>
