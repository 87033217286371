import { axiosInstance } from "@/api/connection"

class CouponService {
	async verifyCoupon(data) {
		const instance = axiosInstance()
		return instance.post(`verify-coupon`, data)
	}
}

export default new CouponService()
