import { createStore } from "vuex"

export default createStore({
	state() {
		return {
			product: {},
			user: {},
			token: null
		}
	},
	getters: {
		getProduct: (state) => {
			state.product
		},
		getUser: (state) => {
			state.user
		},
		getPhone(state) {
			try {
				const user = state.user

				if (user.userInfomartions.phone) {
					const prefix = user.userInfomartions.phone_prefix
					const phone = user.userInfomartions.phone
					return `(${prefix}) ${phone}`
				}
			} catch (error) {
				return "N/A"
			}
		},
		getToken: (state) => {
			state.token
		}
	},
	mutations: {
		setProduct: (state, product) => {
			state.product = product
		},
		setUser: (state, user) => {
			state.user = user
		},
		setToken: (state, token) => {
			state.token = token
		}
	},
	actions: {
		setProduct: (context, product) => {
			context.commit("setProduct", product)
		},
		setUser: (context, user) => {
			context.commit("setUser", user)
		},
		setToken: (context, token) => {
			context.commit("setToken", token)
		}
	},
	modules: {}
})
