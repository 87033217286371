<template>
	<section>
		<link rel="preconnect" href="https://fonts.googleapis.com" /><link
			rel="preconnect"
			href="https://fonts.gstatic.com"
			crossorigin
		/><link
			href="https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;700&display=swap"
			rel="stylesheet"
		/>
		<div class="grid">
			<div id="item-0">
				<div class="curso__header">
					<div class="curso__header___select">
						<h2>Minha conta</h2>
					</div>
					<div class="curso__header___cursos">
						<a href="/meus-cursos"><h2>cursos</h2></a>
					</div>
					<div class="curso__header___menu">
						<a href="#" class="botao-menu">
							<img src="../assets/menu.svg" alt="" />
						</a>
					</div>
				</div>
			</div>
			<div id="item-1">
				<div class="dados">
					<div class="dados__titulo">
						<h2>dados pessoais</h2>
					</div>

					<div>
						<img src="../assets/person.svg" alt="" />
					</div>
					<h1 class="dados__nome">nome completo</h1>
					<h3 class="dados__email">teste@gmail.com</h3>
					<h3 class="dados__telefone">(11) 99999-9999</h3>
				</div>
			</div>
			<div id="item-2">
				<div class="minha-conta__botoes">
					<button class="minha-conta__botao --ativo">
						Minha Conta
					</button>
					<a href="/meus-cursos" class="minha-conta__botao"
						>Meus Cursos</a
					>
				</div>
				<div class="minha-conta">
					<div class="formulario--editar-perfil">
						<div class="field__form">
							<h3>Nome</h3>
							<input
								type="text"
								placeholder="Nome Completo"
								class="form__nome--completo"
								access="false"
								required="required"
								aria-required="true"
								name="nome"
							/>
							<img src="../assets/lapis.svg" alt="" />
						</div>
						<div class="field__form">
							<h3>E-mail</h3>
							<input
								type="email"
								placeholder="teste@gmail.com"
								class="form__email"
								access="false"
								required="required"
								aria-required="true"
								name="email"
							/>
							<img src="../assets/lapis.svg" alt="" />
						</div>
						<div class="field__form">
							<h3>Senha</h3>
							<input
								type="password"
								placeholder="*********"
								class="form__senha"
								access="false"
								required="required"
								aria-required="true"
								name="senha"
							/>
							<img src="../assets/lapis.svg" alt="" />
						</div>
						<div class="field__form">
							<h3>CPF</h3>
							<input
								type="number"
								placeholder="xxx.xxx.xxx-xx"
								class="form__cpf"
								name="cpf"
								access="false"
								id="cpf"
								required="required"
								aria-required="true"
							/>
							<img src="../assets/lapis.svg" alt="" />
						</div>
						<div class="field__form">
							<h3>CRM</h3>
							<input
								type="number"
								placeholder="-"
								class="form__crm"
								name="crm"
								access="false"
								id="crm"
								required="required"
								aria-required="true"
							/>
							<img src="../assets/lapis.svg" alt="" />
						</div>
						<div class="field__form">
							<h3>Telefone</h3>
							<input
								type="number"
								placeholder="(xx) xxxxx-xxxx"
								class="form__tel"
								access="false"
								id="control-1762440"
								required="required"
								aria-required="true"
								name="telefone"
							/>
							<img src="../assets/lapis.svg" alt="" />
						</div>
					</div>
				</div>
				<div class="minha-conta__botoes">
					<button class="minha-conta__botao --ativo">
						Editar Perfil
					</button>
					<a href="/minha-conta" class="minha-conta__botao">
						Salvar Alterações
					</a>
				</div>
			</div>
			<div id="item-3">
				<a href="https://gruposantajoana.com.br/ensinoepesquisa/"
					><img src="..\assets\Logo_gsj.png" alt="" class="logo"
				/></a>
			</div>
		</div>
		<div class="separador">
			<div class="fale-conosco">
				<a href="#"
					><h2>
						Fale Conosco
						<img src="../assets/seta-baixo-black.svg" alt="" /></h2
				></a>
				<div class="social">
					<h2>Social</h2>
					<a href="#"><img src="../assets/Face.svg" alt="" /></a>
					<a href="#"><img src="../assets/Insta.svg" alt="" /></a>
					<a href="#"><img src="../assets/Youtube.svg" alt="" /></a>
					<a href="#"><img src="../assets/Linkedin.svg" alt="" /></a>
				</div>
				<a href="#"
					><h2>
						Política de Privacidade
						<img src="../assets/seta-baixo-black.svg" alt="" /></h2
				></a>
			</div>
		</div>
		<div class="footer">
			<textfield class="footer" readonly>
				<p><b>© 2020 Santa Joana. Todos os direitos reservados</b></p>
				<p>
					Rua do Paraíso, 432 | CEP 04103-000 | Paraíso | São Paulo |
					SP | 11 5080 6000
				</p>
				<p>Responsável Técnico: Dr.Eduardo Rahme Amaro | CRM 31624</p>
			</textfield>
		</div>
	</section>
</template>
<style scoped>
.grid {
	display: grid;

	grid-template: 17vh / 40% 60%;

	height: 100vh;
}

#item-0 {
	background-color: #fff;
	grid-row: 1 / 2;
	grid-column: 2 / 3;
	display: flex;
	flex-direction: row-reverse;
}
#item-1 {
	background-color: #00a7ed;
	grid-row: 2 / 4;
	grid-column: 1 / 2;
}
#item-2 {
	background-color: #00449d;
	grid-row: 2 / 4;
	grid-column: 2 / 3;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}
#item-3 {
	background-color: #fff;
	grid-row: 1 / 2;
	grid-column: 1 / 2;

	text-align: left;
	padding: 2.5% 2.5%;
}

.logo {
	margin-left: 10%;
}

.curso__header {
	display: flex;
	align-items: center;
	justify-content: space-around;
	font-family: "Montserrat", sans-serif;
	font-weight: 200;
	height: 100%;
	width: 50%;
}

.curso__header___select {
	display: flex;
	justify-content: center;
	width: 50%;
	text-transform: uppercase;
	height: 100%;
	align-items: center;
	border-left: 1px black solid;
}

.curso__header___select h2 {
	font-weight: 400;
}

.curso__header___cursos {
	display: flex;
	justify-content: center;
	border-right: 1px solid black;
	border-left: 1px solid black;
	width: 30%;
	text-transform: uppercase;
	height: 100%;
	align-items: center;
}
.curso__header___cursos h2 {
	font-weight: 400;
}
.curso__header___cursos:hover {
	border-right: 1.5px solid black;
	border-left: 1.5px solid black;
	background-color: #00449d;
	color: #fff;
	cursor: pointer;
	transition: all 120ms ease-in-out;
}
.curso__header___cursos a {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: black;
}

.curso__header___cursos a:hover {
	color: white;
	transition: all 120ms ease-in-out;
}
.curso__header___menu {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20%;
	height: 100%;
}

.curso__header___menu:hover {
	background-color: #00449d;
	cursor: pointer;
	transition: all 120ms ease-in-out;
}

.curso__header___menu:hover .botao-menu {
	filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(90deg)
		brightness(99%) contrast(105%);
	transition: all 120ms ease-in-out;
}

.minha-conta {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	color: #fff;
	border: 2px white solid;
	border-radius: 8px;
	height: 50%;
	width: 60%;
	font-family: "Montserrat", sans-serif;
	font-size: 200;
}

.formulario--editar-perfil {
	display: flex;
	width: 95%;
	flex-direction: column;
}

.field__form {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-evenly;
	font-family: "Montserrat", sans-serif;
}

.field__form h3 {
	width: 20%;
	margin-right: 60px;
	font-weight: 200;
}

.form__nome--completo,
.form__email,
.form__senha,
.form__cpf,
.form__crm,
.form__tel {
	background: none;
	outline: none;
	border: 0;
	border-bottom: 2px solid #fff;
	color: #fff;
	height: 30px;
	font-weight: 200;
	font-size: 16px;
	margin-right: 20px;
	padding: 0 0 5px 7px;
	width: 70%;
	font-weight: 200;
}

.form__nome--completo::placeholder,
.form__email::placeholder,
.form__senha::placeholder,
.form__cpf::placeholder,
.form__crm::placeholder,
.form__tel::placeholder {
	font-family: "Montserrat", sans-serif;
	color: #fff;
	font-weight: 200;
	font-size: 18px;
	letter-spacing: 2.5px;
}

.minha-conta__botao {
	align-items: center;
	font-family: "Montserrat", sans-serif;
	font-weight: bold;
	font-size: 18px;
	border: 2px white solid;
	border-radius: 10px;
	padding: 10px 20px;
	color: #fff;
	background-color: #00449d;
	margin-left: 10px;
	text-decoration: none;
}

.minha-conta__botao:hover,
.--ativo {
	border: 2px white solid;
	cursor: pointer;
	background-color: #fff;
	color: #00a7ed;
	transition: all 120ms ease-in-out;
}

.minha-conta__botoes {
	display: flex;
	justify-content: flex-start;
	margin: 15px 0;
	width: 62%;
}

.social h2 {
	margin: 0;
}

.social a:not(:first-of-type) {
	margin-left: 20px;
	scale: 120%;
}

@media only screen and (max-width: 769px) {
	#item-0 {
		grid-area: header-cursos;
	}
	#item-1 {
		grid-area: pagamento;
	}
	#item-2 {
		grid-area: login;
	}
	#item-3 {
		grid-area: header-logo;
	}
	.grid {
		grid-template:
			"header-logo header-cursos"
			"login login"
			"pagamento pagamento";

		height: auto;
		grid-template-columns: 2fr 5fr;
	}

	#item-1 {
		display: none;
	}

	#item-2 {
		padding: 50px 0;
	}

	.minha-conta {
		height: auto;
		width: 80%;
	}

	.minha-conta__botoes {
		width: 80%;
	}

	.field__form {
		margin: 7px 0;
	}
	.logo {
		width: 90%;
	}
	.curso__header {
		width: 65%;
	}
	.curso__header___select {
		display: none;
	}

	.curso__header___cursos {
		width: 50%;
		border-left: 1px solid black;
	}

	.curso__header___cursos:hover {
		border-right: 0;
		border-left: 0;
	}

	.curso__header___menu {
		width: 50%;
	}
}

@media only screen and (max-width: 480px) {
	* {
		font-size: 99%;
	}

	.minha-conta {
		width: 90%;
	}

	.minha-conta__botoes {
		width: 90%;
	}
	.minha-conta__botao {
		font-size: 10px;
	}
	.grid {
		grid-template-columns: 2fr 3fr;
	}

	.curso__header {
		width: 90%;
	}
	.botao-menu img {
		width: 70%;
	}
	.curso__header___menu {
		width: 30%;
	}

	.curso__header___cursos {
		width: 70%;
	}

	.form__nome--completo::placeholder,
	.form__email::placeholder,
	.form__senha::placeholder,
	.form__cpf::placeholder,
	.form__crm::placeholder,
	.form__tel::placeholder {
		font-size: 12px;
	}

	.form__nome--completo,
	.form__email,
	.form__senha,
	.form__cpf,
	.form__crm,
	.form__tel {
		font-size: 12px;
		padding: 0;
		height: 15px;
	}

	.social a:not(:first-of-type) {
		margin-left: 10px;
		scale: 100%;
	}
}
</style>
<script scoped>
document.querySelector("html").style.margin = 0
document.querySelector("html").style.padding = 0
document.querySelector("html").style.width = "100%"

document.querySelector("body").style.margin = 0
document.querySelector("body").style.padding = 0
document.querySelector("body").style.width = "100%"
</script>
