import { exceptions } from "@/api/exceptions"
import couponService from "@/services/Coupon.service"

import { hideLoading, showLoading } from "@/utils/loading"

class CouponController {
	async verifyCoupon(data) {
		try {
			showLoading()

			const response = await couponService.verifyCoupon(data)

			hideLoading()

			if (response !== null) {
				if (response.status === 200) {
					return response.data
				} else {
					alert(response.data)
				}
			}
		} catch (error) {
			alert(exceptions(error))
			hideLoading()
		}

		return null
	}
}

export default new CouponController()
