import { axiosInstance } from "@/api/connection"

class ProductService {
	async getBySlug(slug, params) {
		const instance = axiosInstance()
		return instance.get(`products_slug/${slug}`, { params })
	}
}

export default new ProductService()
