<template>
	<div class="separador">
		<div class="fale-conosco">
			<a href="#"
				><h2>
					Fale Conosco
					<img src="../assets/seta-baixo-black.svg" alt="" /></h2
			></a>
			<div class="social">
				<h2>Social</h2>
				<a href="#"><img src="../assets/Face.svg" alt="" /></a>
				<a href="#"><img src="../assets/Insta.svg" alt="" /></a>
				<a href="#"><img src="../assets/Youtube.svg" alt="" /></a>
				<a href="#"><img src="../assets/Linkedin.svg" alt="" /></a>
			</div>
			<a href="#"
				><h2>
					Política de Privacidade
					<img src="../assets/seta-baixo-black.svg" alt="" /></h2
			></a>
		</div>
	</div>
	<div class="footer">
		<textfield class="footer" readonly>
			<p><b>© 2020 Santa Joana. Todos os direitos reservados</b></p>
			<p>
				Rua do Paraíso, 432 | CEP 04103-000 | Paraíso | São Paulo | SP |
				11 5080 6000
			</p>
			<p>Responsável Técnico: Dr.Eduardo Rahme Amaro | CRM 31624</p>
		</textfield>
	</div>
</template>

<script>
export default {}
</script>

<style></style>
