<template>
	<div>
		<LayoutShoppingCart>
			<div id="item-2">
				<div class="campo-pagamento">
					<div class="linha">
						<img src="@/assets/Linha_sucesso.png" alt="" />
					</div>
					<div class="form-text form-text--cartao">
						<h2>Estamos processando seu pagamento</h2>
						<p>
							Agradecemos pelas sua compra, agora estamos
							processando seu pagamento e sua compra será
							confirmada em até 1 dia útil.
						</p>
					</div>
				</div>
			</div>
		</LayoutShoppingCart>
	</div>
</template>

<script>
import LayoutShoppingCart from "@/components/LayoutShoppingCart.vue"

export default {
	components: {
		LayoutShoppingCart
	},
	mounted() {
		setTimeout(() => {
			let group = this.$route.query.grupo
			if (group == "faculdade") {
				window.location.href = `https://faculdadesantajoana.com.br/`
			} else {
				window.location.href =
					"https://gruposantajoana.com.br/ensinoepesquisa/"
			}
		}, 2500)
	}
}
</script>

<style scoped>
p {
	font-family: Santral;
	font-size: 15px;
	font-weight: 200;
	line-height: 21px;
	letter-spacing: 0.08em;
	text-align: center;
	margin: 40px;
}

#item-2 {
	background-color: #00449d;
	grid-row: 2 / 4;
	grid-column: 2 / 3;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start !important;
	padding-top: 150px;
	width: 100%;
}

.linha img {
	width: 90%;
}

@media only screen and (max-width: 769px) {
	#item-2 {
		padding-top: 80px;
	}
	.linha {
		margin-top: 0;
	}
	.linha img {
		width: 90%;
	}
}
</style>
