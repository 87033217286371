import { exceptions } from "@/api/exceptions"
import socialMediaService from "@/services/SocialMedia.service"

class SocialMediaController {
	async save(data) {
		try {
			const response = await socialMediaService.save(data)

			if (response !== null) {
				if (response.status === 201) {
					return true
				} else {
					alert("Erro ao buscar")
				}
			}
		} catch (error) {
			alert(exceptions(error))
		}

		return false
	}
}

export default new SocialMediaController()
