<template>
	<div class="loading-widget hidden" id="loading">
		<div class="loader"></div>
	</div>
</template>

<script>
export default {}
</script>

<style>
.loading-widget {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	backdrop-filter: blur(3px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.hidden {
	visibility: hidden;
}

.loader {
	width: 85px;
	height: 85px;
	border: 10px solid #00a7ed;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
