<template>
	<div class="page">
		<header>
			<img src="@/assets/Logo_gsj_grande.png" alt="" />
		</header>

		<main class="content">
			<div class="card">
				<p class="title">esqueci minha senha</p>
				<!-- <p class="description">
					Todos procedimentos para recuperar sua senha foram enviados
					para seu e-mail
				</p> -->
				<div class="card__content">
					<input
						type="text"
						class="input-email"
						placeholder="email"
						v-model="email"
					/>

					<button @click="recoverPassword">Recuperar senha</button>
				</div>
				<p class="prev" @click="backLogin">Voltar para o Login</p>
			</div>
		</main>

		<footer>
			<FooterPattern />
		</footer>
	</div>
</template>

<script>
import FooterPattern from "@/components/FooterPattern.vue"
import userController from "@/controllers/User.controller"
import { hideLoading, showLoading } from "@/utils/loading"

export default {
	components: {
		FooterPattern
	},
	data() {
		return {
			email: ""
		}
	},
	methods: {
		backLogin() {
			this.$router.push("/")
		},
		async recoverPassword() {
			if (this.email === "") {
				alert("Preencha o campo de email")
				return
			}

			showLoading()

			const response = await userController.requestPasswordReset({
				email: this.email.trim()
			})

			hideLoading()

			if (response && response.status === 200) {
				this.$router.push("/recuperar-senha/sucesso")
			}
		}
	}
}
</script>

<style scoped>
header {
	margin: 80px auto;
}

p {
	margin: 0;
	padding: 0;
}

.page {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
}

.content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.card {
	background: #00449d;
	box-shadow: 5px 7px 20px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	width: 485px;
	height: 280px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.card__content {
	width: 100%;
	gap: 20px;
	flex-direction: column;
	display: flex;
	align-items: center;
}

button {
	width: 85%;
	height: 40px;
	background: #00a7ed;
	border-radius: 8px;
	border: none;
	color: white;
	font-family: "Santral-Bold";
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	cursor: pointer;
}

.title {
	font-family: "Santral-Bold";
	letter-spacing: 0.5em;
	text-transform: uppercase;
	color: #00a7ed;
	font-size: 16px;
	line-height: 19px;
}

.description {
	font-family: "Santral-Bold";
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.08em;
	color: #ffffff;
}

.prev {
	font-family: "Santral";
	text-align: left;
	color: #ffffff;
	font-weight: 200;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.08em;
	margin: 0 20px;
	cursor: pointer;
	width: 80%;
}
.input-email {
	width: 80%;
	height: 35px;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid white;
	color: white;
	font-family: "Santral";
	font-size: 18px;
}

.input-email:focus {
	outline: none;
}

.input-email::placeholder {
	color: white;
	font-family: "Santral";
	font-size: 18px;
}

@media only screen and (max-width: 769px) {
	.card {
		margin: 0 25px;
	}
}

@media only screen and (max-width: 480px) {
	.card p {
		font-size: 14px;
	}
}
</style>
