export const getPrefix = (phone) => {
	const regexPrefix = /\((.*?)\)/gm

	try {
		return regexPrefix.exec(phone)[1]
	} catch (error) {
		return ""
	}
}

export const getPhoneWithouPrefix = (phone) => {
	const regexPrefix = /\((.*?)\)/gm

	try {
		return phone
			.replaceAll(regexPrefix.exec(phone)[0], "")
			.trim()
			.replaceAll("-", "")
	} catch (error) {
		return phone
	}
}
