export const init = () => {
	window.addEventListener("DOMContentLoaded", () => {
		const buttonClose = document.querySelector(".modal__button-close")

		if (buttonClose)
			buttonClose.addEventListener("click", () => {
				const element = document.getElementById("login-page")
				element.classList.add("login-unselected")
				element.classList.remove("login-selected")
			})
	})

	window.addEventListener("DOMContentLoaded", () => {
		const buttonOpen = document.querySelector(".modal__button-open")

		if (buttonOpen)
			buttonOpen.addEventListener("click", () => {
				const element = document.getElementById("login-page")
				element.classList.add("login-selected")
				element.classList.remove("login-unselected")
			})
	})
}
