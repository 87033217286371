import { axiosInstance } from "@/api/connection"

class SocialMediaService {
	async save(data) {
		const instance = axiosInstance()
		return instance.post("/social_media", data)
	}
}

export default new SocialMediaService()
