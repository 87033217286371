<template>
	<div class="minha-conta">
		<div class="minha-conta__nome">
			<h3>Nome</h3>
			<p class="minha-conta__dados">
				{{ this.$store.state.user.name }}
			</p>
		</div>
		<div class="minha-conta__email">
			<h3>E-mail</h3>
			<p class="minha-conta__dados">
				{{ this.$store.state.user.email }}
			</p>
		</div>
		<div class="minha-conta__senha">
			<h3>Senha</h3>
			<p class="minha-conta__dados">*********</p>
		</div>
		<div class="minha-conta__cpf">
			<h3>CPF&#x2003;</h3>
			<p class="minha-conta__dados">
				{{ this.$store.state.user.userInfomartions.cpf }}
			</p>
		</div>
		<div class="minha-conta__crm">
			<h3>CRM&#x2003;</h3>
			<p class="minha-conta__dados">
				{{ this.$store.state.user.userInfomartions.crm }}
			</p>
		</div>
		<div class="minha-conta__telefone">
			<h3>Telefone</h3>
			<p class="minha-conta__dados">
				{{ this.$store.getters["getPhone"] }}
			</p>
		</div>
		<!-- aniversário -->
		<div class="minha-conta__aniversario">
			<h3>Aniversário</h3>
			<p class="minha-conta__dados">
				{{
					this.$store.state.user.userInfomartions.birth_date
						.split("-")
						.reverse()
						.join("/")
				}}
			</p>
		</div>
		<!-- rua -->
		<div class="minha-conta__rua">
			<h3>Rua</h3>
			<p class="minha-conta__dados">
				{{ this.$store.state.user.userInfomartions.street }}
			</p>
		</div>

		<!-- número -->
		<div class="minha-conta__numero">
			<h3>Número</h3>
			<p class="minha-conta__dados">
				{{ this.$store.state.user.userInfomartions.number }}
			</p>
		</div>

		<!-- cep -->
		<div class="minha-conta__cep">
			<h3>CEP</h3>
			<p class="minha-conta__dados">
				{{
					this.$store.state.user.userInfomartions.cep.replace(
						/^(\d{5})(\d)/,
						"$1-$2"
					)
				}}
			</p>
		</div>

		<!-- cidade -->
		<div class="minha-conta__cidade">
			<h3>Cidade</h3>
			<p class="minha-conta__dados">
				{{ this.$store.state.user.userInfomartions.city }}
			</p>
		</div>

		<!-- estado -->
		<div class="minha-conta__estado">
			<h3>Estado</h3>
			<p class="minha-conta__dados">
				{{ this.$store.state.user.userInfomartions.state }}
			</p>
		</div>
	</div>
	<!-- <div class="minha-conta__botoes">
		<a href="/editar-perfil" class="minha-conta__botao">Editar Perfil</a>
	</div> -->
</template>

<script>
export default {}
</script>

<style>
.minha-conta {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	color: #fff;
	border: 2px white solid;
	border-radius: 8px;
	width: 70%;
	font-family: "Montserrat", sans-serif;
	font-size: 200;
	margin-bottom: 40px;
}

.minha-conta__nome,
.minha-conta__email,
.minha-conta__senha,
.minha-conta__cpf,
.minha-conta__crm,
.minha-conta__telefone,
.minha-conta__aniversario,
.minha-conta__rua,
.minha-conta__numero,
.minha-conta__cep,
.minha-conta__cidade,
.minha-conta__estado {
	font-family: "Montserrat", sans-serif;
	font-weight: 200;
	display: flex;
	justify-content: space-between;
	width: 90%;
}

.minha-conta__nome p,
.minha-conta__email p,
.minha-conta__senha p,
.minha-conta__cpf p,
.minha-conta__crm p,
.minha-conta__telefone p,
.minha-conta__aniversario p,
.minha-conta__rua p,
.minha-conta__numero p,
.minha-conta__cep p,
.minha-conta__cidade p,
.minha-conta__estado p {
	width: 80%;
	letter-spacing: 2px;
}
.minha-conta__telefone,
.minha-conta__nome h3,
.minha-conta__email h3,
.minha-conta__senha h3,
.minha-conta__cpf h3,
.minha-conta__crm h3,
.minha-conta__telefone h3,
.minha-conta__aniversario h3,
.minha-conta__rua h3,
.minha-conta__numero h3,
.minha-conta__cep h3,
.minha-conta__cidade h3,
.minha-conta__estado h3 {
	font-family: "Montserrat", sans-serif;
	font-weight: 200;
}

.minha-conta__dados {
	display: flex;
	border-bottom: 1px white solid;
	width: 60%;
}

.minha-conta__botao {
	align-items: center;
	font-family: "Montserrat", sans-serif;
	font-weight: bold;
	font-size: 18px;
	border: 2px white solid;
	border-radius: 10px;
	padding: 10px 20px;
	color: #fff;
	background-color: #00449d;
	margin-left: 10px;
	text-decoration: none;
}

.minha-conta__botao:hover,
.--ativo {
	border: 2px white solid;
	cursor: pointer;
	background-color: #fff;
	color: #00a7ed;
	transition: all 120ms ease-in-out;
}

.minha-conta__botoes {
	display: flex;
	justify-content: flex-start;
	margin: 15px 0;
	width: 72%;
}

@media only screen and (max-width: 1250px) and (min-width: 770px) {
	.minha-conta {
		width: 80%;
	}

	.minha-conta__botoes {
		width: 82%;
	}
}

@media only screen and (max-width: 769px) {
	.minha-conta__nome p,
	.minha-conta__email p,
	.minha-conta__senha p,
	.minha-conta__cpf p,
	.minha-conta__crm p,
	.minha-conta__telefone p,
	.minha-conta__aniversario p,
	.minha-conta__rua p,
	.minha-conta__numero p,
	.minha-conta__cep p,
	.minha-conta__cidade p,
	.minha-conta__estado p {
		width: 68%;
		letter-spacing: 2px;
	}
}
</style>
