import { exceptions } from "@/api/exceptions"
import orderService from "@/services/Order.service"
import userService from "@/services/User.service"

import { hideLoading, showLoading } from "@/utils/loading"

class OrderController {
	async createOrder(data) {
		try {
			showLoading()

			const response = await orderService.createOrder(data)

			hideLoading()

			if (response !== null) {
				if (response.status === 201) {
					try {
						userService.myProfile()
					} catch (error) {
						//
					}
					return response.data
				} else {
					alert(response.data)
				}
			}
		} catch (error) {
			alert(exceptions(error))
			hideLoading()
		}

		return null
	}
}

export default new OrderController()
