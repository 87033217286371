<template>
	<div class="valor-pagamento">
		<div class="valor-pagamento--valor">
			<h2>R$</h2>
			<h1>XXX,XX</h1>
		</div>
		<h3>Você será encaminhado para o pagamento.</h3>
	</div>
</template>

<script>
export default {}
</script>

<style></style>
