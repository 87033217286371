import { createRouter, createWebHistory } from "vue-router"
import HomeView from "../views/home/HomeView.vue"
import Carrinho from "../views/carrinho_page/view/CarrinhoView.vue"
import EditarPerfil from "../views/EditarPerfilView.vue"
import Perfil from "../views/perfil/view/MinhaContaView.vue"
import Pagamento from "../views/carrinho_pagamento_page/view/CarrinhoPagamentoView.vue"
import RecoverPassword from "../views/forget_password/RecoverPassword.vue"
import ResetPassword from "../views/forget_password/ResetPassword.vue"
import SuccessRecoverPassword from "../views/forget_password/SuccessRecoverPassword.vue"
import SuccessResetPassword from "../views/forget_password/SuccessResetPassword.vue"
import SuccessPage from "../views/success_page/SuccessPage.vue"
import NotFound from "../views/NotFoundPage.vue"
import cookieService from "@/services/Cookie.service"
import TermosUso from "../views/TermosUsoPage.vue"

const routes = [
	{
		path: "/",
		name: "Home",
		component: HomeView
	},
	{
		path: "/carrinho",
		name: "Carrinho",
		component: Carrinho
	},
	{
		path: "/perfil",
		name: "MinhaConta",
		component: Perfil
	},
	{
		path: "/editar-perfil",
		name: "EditarPerfil",
		component: EditarPerfil
	},
	{
		path: "/pagamento",
		name: "Pagamento",
		component: Pagamento
	},
	{
		path: "/recuperar-senha",
		name: "RecuperarSenha",
		component: RecoverPassword
	},
	{
		path: "/recuperar-senha/sucesso",
		name: "SucessoRecuperarSenha",
		component: SuccessRecoverPassword
	},
	{
		path: "/redefinicao-senha/:token",
		name: "RedefinicaoPassword",
		component: ResetPassword
	},
	{
		path: "/redefinicao-senha/sucesso",
		name: "SucessoRedefinirSenha",
		component: SuccessResetPassword
	},
	{
		path: "/sucesso",
		name: "Sucesso",
		component: SuccessPage
	},
	{
		path: "/termos-de-uso",
		name: "Termos de uso",
		component: TermosUso
	},
	{ path: "/:catchAll(.*)", component: NotFound }
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	const publicPages = ["/perfil", "/editar-perfil", "/pagamento"]
	const authRequired = publicPages.includes(to.path)
	const loggedIn = cookieService.getCookie("token") !== null

	if (authRequired && !loggedIn) {
		return next("/")
	}

	next()
})

export default router
