<template>
	<div class="formulario">
		<div class="linha">
			<img src="../../../assets/Linha.png" alt="" />
		</div>
		<div class="form-text">
			<h2>dados pessoais</h2>
			<h3>
				já é cadastrado?
				<button class="leitura__text modal__button-open">
					faça login
				</button>
			</h3>
		</div>
		<input
			type="text"
			placeholder="Nome Completo"
			class="form__nome--completo"
			access="false"
			id="control-7906556"
			required="required"
			aria-required="true"
			name="nome"
			v-model="name"
		/>
		<input
			type="email"
			placeholder="Email"
			class="form__email"
			access="false"
			id="control-6956089"
			required="required"
			aria-required="true"
			name="email"
			v-model="email"
		/>
		<div class="senhas">
			<input
				type="password"
				placeholder="Senha"
				class="form__senha"
				access="false"
				id="control-7722384"
				required="required"
				aria-required="true"
				name="senha"
				v-model="password"
			/>

			<input
				type="password"
				placeholder="Confirmar senha"
				class="form__conf--senha"
				name="confirmar-senha"
				access="false"
				id="confirmar-senha"
				required="required"
				aria-required="true"
				v-model="confirmPassword"
			/>
		</div>
		<div class="crm-cpf">
			<!-- <label for="crm" class="formbuilder-checkbox-group-label"></label>
			<div class="form__crm">
				<div class="square__checked form__crm">
					<input
						access="false"
						name="crm"
						type="checkbox"
						v-model="hasCRM"
					/>
					<label class="form__crm___label" for="crm"
						>Possui CRM?</label
					>
				</div>
			</div> -->

			<!-- <label for="cpf"></label> -->
			<input
				placeholder="CPF"
				class="form__cpf"
				name="cpf"
				access="false"
				id="cpf"
				required="required"
				aria-required="true"
				v-maska
				data-maska="###.###.###-##"
				v-model="cpf"
			/>

			<input
				placeholder="CRM"
				class="form__cpf"
				name="crm"
				access="false"
				id="crm"
				required="required"
				aria-required="true"
				v-model="crm"
				v-if="!hiddenDoctorFields"
			/>
		</div>
		<div class="telefones">
			<select
				placeholder="Tipo de telefone"
				class="form__tipo"
				id="tipo-telefone"
				required="required"
				aria-required="true"
				v-model="phoneType"
			>
				<option disabled="null" selected="null">
					Tipo de telefone
				</option>
				<option id="select">Telefone</option>
				<option id="select">Celular</option>
			</select>

			<input
				:placeholder="phoneType"
				class="form__tel"
				access="false"
				id="control-1762440"
				required="required"
				aria-required="true"
				name="telefone"
				v-maska
				v-model="phone"
				:data-maska="
					phoneType === 'Celular'
						? '(##) #####-####'
						: '(##) ####-####'
				"
			/>

			<input
				placeholder="Data de nascimento"
				type="text"
				class="form__tel form__birth"
				access="false"
				id="control-1762440"
				required="required"
				aria-required="true"
				name="telefone"
				v-maska
				data-maska="##/##/####"
				v-model="birthDate"
				@blur="validateBirthDate"
			/>
		</div>
		<div class="address">
			<input
				type="text"
				placeholder="CEP"
				class="form__cep"
				access="false"
				required="required"
				aria-required="true"
				name="cep"
				v-model="cep"
				v-maska
				data-maska="#####-###"
				style="flex: 1"
			/>

			<input
				type="text"
				placeholder="Cidade"
				class="form__city"
				access="false"
				id="control-7906556"
				required="required"
				aria-required="true"
				name="city"
				v-model="city"
				style="flex: 1"
			/>
		</div>

		<div class="address">
			<!-- street -->

			<input
				type="text"
				placeholder="Rua"
				class="form__street"
				access="false"
				id="control-7906556"
				required="required"
				aria-required="true"
				name="street"
				v-model="street"
				style="flex: 1"
			/>

			<!-- number -->
			<input
				type="text"
				placeholder="Número"
				class="form__number"
				access="false"
				id="control-7906556"
				required="required"
				aria-required="true"
				name="number"
				v-model="number"
				style="flex: 1"
			/>
		</div>

		<div class="field-leitura">
			<label for="leitura" class=""></label>
			<div class="leituras">
				<div class="">
					<label for="leitura-00" class="label--geral">
						<input
							class="form__leitura00"
							name="terms"
							access="false"
							id="leitura-00"
							required="required"
							aria-required="true"
							type="checkbox"
							v-model="confirmTerms"
						/><span></span>
						<div>
							Declaro que li e aceito os
							<a
								v-bind:href="termsLink"
								v-bind:target="linkMethod"
								class="leitura__text"
							>
								{{ termsName }}
							</a>
						</div>
					</label>
				</div>
				<div class="">
					<label for="leitura-0" class="label--geral">
						<input
							class="form__leitura0"
							name="privacy_policy"
							access="false"
							id="leitura-0"
							required="required"
							aria-required="true"
							type="checkbox"
							v-model="confirmContract"
						/><span></span>
						<div>
							Declaro que li e concordo com a
							<a
								v-bind:href="contractLink"
								v-bind:target="linkMethod"
								class="leitura__text"
							>
								{{ contractName }}
							</a>
						</div>
					</label>
				</div>
				<div class="">
					<label for="leitura-1" class="label--geral">
						<input
							class="form__leitura1"
							name="share_data"
							access="false"
							id="leitura-1"
							required="required"
							aria-required="true"
							type="checkbox"
							v-model="ConfirmShareData"
						/><span></span>
						<div>
							Declaro que aceito compartilhar meus dados com
							patrocinador para disponibilização de lista de de
							presença&#8194;
						</div>
					</label>
				</div>
				<div class="">
					<label for="leitura-2" class="label--geral">
						<input
							class="form__leitura2"
							name="use_image"
							access="false"
							id="leitura-2"
							required="required"
							aria-required="true"
							type="checkbox"
							v-model="confirmUseImage"
						/><span></span>
						<div>
							Declaro que concordo com o uso e reprodução da minha
							imagem unicamente para fins de utilização e
							divulgação do evento
							<strong>{{ this.$store.state.product.name }}</strong
							>&#8194;
						</div>
					</label>
				</div>
			</div>
		</div>
		<div class="field-btn">
			<button
				type="submit"
				class="form__btn btn-primary btn"
				name="btn"
				access="false"
				style="primary"
				id="btn"
				@click="save"
			>
				Próximo Passo&#10240;
				<img
					src="../../../assets/arrow_forward.svg"
					alt=""
					class="form__btn___img"
				/>
			</button>
		</div>
	</div>

	<vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
</template>

<script>
import { getPrefix, getPhoneWithouPrefix } from "@/utils/formatPhone"
import userController from "@/controllers/User.controller"
import locationService from "@/services/Location.service"

import VueBasicAlert from "vue-basic-alert"
import { hideLoading, showLoading } from "@/utils/loading"
import cookieService from "@/services/Cookie.service"
import productController from "@/controllers/Product.controller"

export default {
	components: {
		VueBasicAlert
	},
	data() {
		return {
			name: "",
			email: "",
			password: "",
			confirmPassword: "",
			phone: "",
			cpf: "",
			crm: "",
			phoneType: "Celular",
			hasCRM: false,
			confirmTerms: false,
			confirmContract: false,
			ConfirmShareData: false,
			confirmUseImage: false,
			birthDate: "",
			cep: "",
			city: "",
			state: "null",
			street: "",
			number: "",
			cna: "null",
			states: [
				{ value: "AC", label: "Acre" },
				{ value: "AL", label: "Alagoas" },
				{ value: "AP", label: "Amapá" },
				{ value: "AM", label: "Amazonas" },
				{ value: "BA", label: "Bahia" },
				{ value: "CE", label: "Ceará" },
				{ value: "DF", label: "Distrito Federal" },
				{ value: "ES", label: "Espírito Santo" },
				{ value: "GO", label: "Goías" },
				{ value: "MA", label: "Maranhão" },
				{ value: "MT", label: "Mato Grosso" },
				{ value: "MS", label: "Mato Grosso do Sul" },
				{ value: "MG", label: "Minas Gerais" },
				{ value: "PA", label: "Pará" },
				{ value: "PB", label: "Paraíba" },
				{ value: "PR", label: "Paraná" },
				{ value: "PE", label: "Pernambuco" },
				{ value: "PI", label: "Piauí" },
				{ value: "RJ", label: "Rio de Janeiro" },
				{ value: "RN", label: "Rio Grande do Norte" },
				{ value: "RS", label: "Rio Grande do Sul" },
				{ value: "RO", label: "Rondônia" },
				{ value: "RR", label: "Roraíma" },
				{ value: "SC", label: "Santa Catarina" },
				{ value: "SP", label: "São Paulo" },
				{ value: "SE", label: "Sergipe" },
				{ value: "TO", label: "Tocantins" }
			],
			querySlug: "",
			contractLink:
				"https://gruposantajoana.com.br/ensinoepesquisa/politica-de-privacidade/",
			linkMethod: "_blank",
			contractName: "política de privacidade",
			termsLink: "/termos-de-uso/",
			termsName: "termos de uso",
			hiddenDoctorFields: true
		}
	},
	watch: {
		cep() {
			if (this.cep.replaceAll("-", "").length === 8) {
				this.getCep()
			} else {
				this.clearAddress()
			}
		},
		state() {
			if (this.state.length === 2) {
				this.state = this.state.toUpperCase()
			}
		}
	},
	methods: {
		async swapContract() {
			this.querySlug = this.$route.query.slug
			console.log(this.querySlug)
			if (
				this.querySlug ==
				"vestibular-vagas-remanescentes-graduacao-em-enfermagem"
			) {
				this.contractLink =
					"https://faculdadesantajoana.com.br/wp-content/uploads/2023/12/Editalvagas_remanescentes20241.pdf"

				this.linkMethod = "_blank"
				this.contractName = "Edital"
			} else {
				this.contractLink =
					"https://gruposantajoana.com.br/ensinoepesquisa/politica-de-privacidade/"
			}

			const product = await productController.getBySlug(
				this.$route.query.slug
					? this.$route.query.slug
					: cookieService.getCookie("slug")
			)
			if (product.group === "faculdade") {
				this.hiddenDoctorFields = true
			} else {
				this.hiddenDoctorFields = false
			}
		},
		isValidDate(dateString) {
			if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false

			const date = Date.parse(dateString.split("/").reverse().join("-"))

			if (isNaN(date)) return false
			return true
		},
		clearAddress() {
			this.city = ""
			this.state = "null"
			this.street = ""
			this.number = ""
		},
		isValidCPF(cpf) {
			const cleanedCPF = cpf.replace(/\D/g, "")

			if (cleanedCPF.length !== 11 || /^(.)\1*$/.test(cleanedCPF)) {
				return false
			}

			const cpfArray = Array.from(cleanedCPF, Number)

			let sum = 0
			let factor = 10

			for (let i = 0; i < 9; i++) {
				sum += cpfArray[i] * factor--
			}

			let remainder = (sum * 10) % 11
			if (remainder === 10 || remainder === 11) {
				remainder = 0
			}

			if (remainder !== cpfArray[9]) {
				return false
			}

			sum = 0
			factor = 11

			for (let i = 0; i < 10; i++) {
				sum += cpfArray[i] * factor--
			}

			remainder = (sum * 10) % 11
			if (remainder === 10 || remainder === 11) {
				remainder = 0
			}

			return remainder === cpfArray[10]
		},
		async getCep() {
			try {
				showLoading()

				await new Promise((resolve) => {
					setTimeout(() => {
						resolve()
					}, 1200)
				})

				const response = await locationService.getLocation(this.cep)

				if (response.status === 200) {
					if (response.data.erro) {
						this.alert("CEP não encontrado", "error")

						this.cep = "null"
						hideLoading()
						return
					}
					this.city = response.data.localidade
					this.state = response.data.uf
					this.street = response.data.logradouro
				}
				hideLoading()
			} catch (error) {
				this.alert("CEP não encontrado")
				hideLoading()
			}
		},
		alert(message, type = "warning", title = "Atenção") {
			this.$refs.alert.showAlert(
				type, // There are 4 types of alert: success, info, warning, error
				message,
				title, // Header of the alert
				{
					iconSize: 35, // Size of the icon (px)
					iconType: "solid", // Icon styles: now only 2 styles 'solid' and 'regular'
					position: "bottom left"
				} // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
			)
		},
		async save() {
			if (this.name === "") {
				this.alert("Preencha o campo nome")
				return
			}

			if (this.email === "") {
				this.alert("Preencha o campo de e-mail")
				return
			}

			// Expressão regular para validar o formato do e-mail
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

			if (!emailRegex.test(this.email)) {
				this.alert("Digite um endereço de e-mail válido")
				return
			}

			if (this.password === "") {
				this.alert("Preencha o campo senha")
				return
			}

			if (this.password.length < 6) {
				this.alert("A Senha deve ter no mínimo 6 caracteres")
				this.password = ""
				this.confirmPassword = ""
				return
			}

			if (this.confirmPassword === "") {
				this.alert("Preencha o campo confirmar senha")
				return
			}

			if (this.password !== this.confirmPassword) {
				this.alert("As senhas não conferem")
				return
			}

			if (this.cpf === "") {
				this.alert("Preencha o campo CPF")
				return
			}

			if (this.isValidCPF(this.cpf) === false) {
				this.alert("Digite um CPF válido")
				return
			}

			if (this.phone === "") {
				this.alert("Preencha o campo telefone")
				return
			}

			if (this.birthDate === "") {
				this.alert("Preencha o campo data de nascimento")
				return
			}

			if (!this.isValidDate(this.birthDate)) {
				this.alert("Data de nascimento inválida")
				return
			}

			if (this.cep === "") {
				this.alert("Preencha o campo CEP")
				return
			}

			if (this.city === "") {
				this.alert("Preencha o campo cidade")
				return
			}

			if (this.state === "null") {
				this.alert("Preencha o campo estado")
				return
			}

			if (this.street === "") {
				this.alert("Preencha o campo rua")
				return
			}

			if (this.number === "") {
				this.alert("Preencha o campo número")
				return
			}

			if (!this.confirmTerms) {
				this.alert("Você precisa aceitar os termos de contrato")
				return
			}

			if (!this.confirmContract) {
				this.alert("Você precisa aceitar o contrato")
				return
			}

			const data = {
				name: this.name,
				email: this.email,
				password: this.password,
				confirmation_password: this.confirmPassword,
				phone: getPhoneWithouPrefix(this.phone),
				phone_prefix: getPrefix(this.phone),
				birth_date: this.birthDate.split("/").reverse().join("-"),
				street: this.street,
				number: this.number,
				cep: this.cep,
				city: this.city,
				state: this.state,
				cna: this.cna === "sim",
				cpf: this.cpf,
				crm: this.crm === "" ? null : this.crm,
				privacy_policy: this.confirmContract,
				share_data: this.ConfirmShareData,
				use_image: this.confirmUseImage,
				terms_use: this.confirmTerms
			}

			showLoading()
			const response = await userController.register(data)
			hideLoading()

			if (response !== null) {
				this.alert("Cadastrado com sucesso", "success", "Sucesso")

				const response = await userController.auth({
					email: this.email,
					password: this.password
				})

				if (response !== null) {
					cookieService.setCookie("token", response.token, 1)
					this.$store.dispatch("setToken", response.token)

					this.$router.push("/pagamento")
				}
			}
		}
	},
	async mounted() {
		await this.swapContract()
	}
}
</script>

<style scoped>
.address {
	flex-wrap: wrap;
	margin-top: 25px;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 15px;
}

select {
	background: #00449d !important;
}

.form__tel,
.form__birth {
	margin: 0 !important;
}

.crm-cpf {
	gap: 15px;
	flex-wrap: wrap !important;
}

.senhas {
	flex-wrap: wrap !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="checkbox"] {
	-webkit-appearance: auto;
}
</style>
