<template>
	<div
		:style="{
			width: width,
			height: height,
			margin: margin
		}"
		class="animation_loader"
	></div>
</template>

<script>
export default {
	props: {
		width: {
			type: String,
			default: "100%"
		},
		height: {
			type: String,
			default: "100%"
		},
		margin: {
			type: String,
			default: "0"
		}
	}
}
</script>

<style>
@keyframes pulse-bg {
	0% {
		background-color: #ddd;
	}
	50% {
		background-color: #d0d0d0;
	}
	100% {
		background-color: #ddd;
	}
}

.animation_loader {
	animation: pulse-bg 1s infinite;
}
</style>
