import { axiosInstance } from "@/api/connection"

class OrderService {
	async createOrder(data) {
		const instance = axiosInstance()
		return instance.post(`order/course`, data)
	}
}

export default new OrderService()
