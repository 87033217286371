<template>
	<div class="valor-pagamento">
		<div class="valor-pagamento--valor">
			<h2>R$</h2>
			<h1>XXX,XX</h1>
		</div>
		<h3>
			Você receberá o boleto bancário na sua caixa de
			<strong>e-mail.</strong>
		</h3>
	</div>
	<div class="prazo-pagamento">
		<img src="@/assets/calendario.svg" alt="" />
		<h3>O prazo de pagamento via boleto bancário é de X dias corridos.</h3>
	</div>
</template>

<script>
export default {}
</script>

<style></style>
