import { exceptions } from "@/api/exceptions"
import productService from "@/services/Product.service"

class ProductController {
	async getBySlug(slug, params) {
		try {
			const response = await productService.getBySlug(slug, params)

			if (response !== null) {
				if (response.status === 200) {
					return response.data
				} else {
					alert("Erro ao buscar")
				}
			}
		} catch (error) {
			alert(exceptions(error))
		}

		return null
	}
}

export default new ProductController()
