import { axiosInstance } from "@/api/connection"

class UserInterestService {
	async registerInterest(data) {
		const instance = axiosInstance()
		const response = await instance.post("public/user-interest", data)
		return response
	}
}

export default new UserInterestService()
