<template>
	<div class="minha-conta">
		<div class="minha-conta__cursos">
			<div v-if="loading">
				<div v-for="i in 3" :key="i">
					<SkeletonWidget
						:width="'100%'"
						:height="'40px'"
						:margin="'15px 0px'"
					/>
				</div>
			</div>

			<div v-else>
				<div v-if="products.length === 0">
					<h3>Nenhum curso encontrado</h3>
				</div>
				<div v-for="p in products" :key="p" v-else>
					<h3>{{ p.name }}</h3>
					<p v-if="p.certificate">
						<a
							:href="url + p.url"
							target="_blank"
							title="Baixar certificado"
							download=""
							>Baixar certificado</a
						>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import userController from "@/controllers/User.controller"
import SkeletonWidget from "@/components/SkeletonWidget.vue"
import { URL } from "@/api/connection"

export default {
	components: {
		SkeletonWidget
	},
	data() {
		return {
			url: URL,
			products: [],
			loading: false
		}
	},
	methods: {
		async setProducts() {
			this.loading = true

			await new Promise((resolve) => {
				setTimeout(() => {
					resolve()
				}, 2000)
			})

			const response = await userController.getProfileProducts()

			this.loading = false

			if (response !== null) {
				this.products = response
			}
		}
	},
	async mounted() {
		this.setProducts()
	}
}
</script>

<style scoped>
.minha-conta {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	color: #fff;
	border: 2px white solid;
	border-radius: 20px;
	height: auto;
	width: 60%;
	font-family: "Montserrat", sans-serif;
	font-size: 200;
}

.minha-conta__cursos {
	display: flex;
	flex-direction: column;
	width: 90%;
	text-align: left;
}

.minha-conta__cursos h3:not(:last-child) {
	margin-top: 15px;
	padding-bottom: 15px;
	border-bottom: #fff 1px solid;
}

.minha-conta__cursos h3:not(:first-child) {
	margin-top: 15px;
	padding-bottom: 15px;
}

.minha-conta__cursos h3 {
	font-family: "Montserrat", sans-serif;
	font-weight: 200;
	margin: 10px 0;
}
.minha-conta__cursos a {
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	text-decoration: none;
	color: #00a7ed;
	background-color: #fff;
	padding: 10px 15px;
	border-radius: 5px;
	margin-bottom: 5px;
	display: inline-block;
}
</style>
