<template>
	<div class="container"><h2>404</h2></div>
</template>

<script>
export default {}
</script>

<style scoped>
.container {
	height: 100vh;
	background-color: #00a7ed;
	display: flex;
	justify-content: center;
	color: white;
	align-items: center;
	font-size: 220px;
}

h2 {
	font-size: 250px;
	font-weight: 400;
}
</style>
