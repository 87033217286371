<template>
	<LayoutShoppingCart>
		<div
			v-if="!this.$store.state.token || this.$store.state.token === null"
		>
			<RegisterProfile />
		</div>
		<div v-else>
			<ConfirmProfile />
		</div>
	</LayoutShoppingCart>

	<LoginModal />
</template>

<script>
import LayoutShoppingCart from "@/components/LayoutShoppingCart.vue"
import LoginModal from "@/components/LoginModal.vue"
import ConfirmProfile from "../components/ConfirmProfile.vue"
import RegisterProfile from "../components/RegisterProfile.vue"
import productController from "@/controllers/Product.controller"
import userInterestController from "@/controllers/UserInterest.controller"
import cookieService from "@/services/Cookie.service"
import { init } from "../tools/init"
import { moneyMask } from "@/utils/moneyMask"

//import userController from "@/controllers/User.controller"
//import cookieService from "@/services/Cookie.service"

export default {
	components: {
		LoginModal,
		ConfirmProfile,
		RegisterProfile,
		LayoutShoppingCart
	},
	data() {
		return {
			product: {},
			total: 0
		}
	},

	methods: {
		moneyMask,
		async setProduct() {
			const slug = this.$route.query.slug
				? this.$route.query.slug
				: cookieService.getCookie("slug")

			if (slug === "") {
				alert("Você precisa escolher um evento para continuar")
				return
			}

			const response = await productController.getBySlug(
				this.$route.query.slug
					? this.$route.query.slug
					: cookieService.getCookie("slug")
			)

			if (response !== null) {
				this.product = response
				if (this.$store.state.token) {
					await userInterestController.registerInterest({
						product: response.id
					})
				}
				this.$store.dispatch("setProduct", response)
				cookieService.setCookie("product", JSON.stringify(response), 1)
				if (this.$route.query.slug)
					cookieService.setCookie("slug", this.$route.query.slug, 1)
			}
		}
	},

	async mounted() {
		init()
		this.setProduct()
		/* var link = document.createElement("meta")
		link.setAttribute("property", "og:url")
		link.content = document.location
		document.getElementsByTagName("head")[0].appendChild(link)

		const image = document.createElement("meta")
		image.setAttribute("property", "og:image")
		;(image.content =
			"https://gruposantajoana.com.br/ensinoepesquisa/wp-content/uploads/2019/01/reducao-mortalidade.jpg"),
			document.getElementsByTagName("head")[0].appendChild(image)

		const title = document.createElement("meta")
		title.setAttribute("property", "og:title")
		title.content = "Grupo Santa Joana - Ecommerce"
		document.getElementsByTagName("head")[0].appendChild(title) */
	}
}
</script>

<style>
.curso__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: "Montserrat", sans-serif;
	font-size: 200;
	height: 100%;
	width: 100%;
}

.curso__header___select {
	display: flex;
	justify-content: center;
	width: 75%;
	text-transform: uppercase;
	height: 100%;
	align-items: center;
	margin-left: 30px;
}

.curso__header___img {
	padding-right: 10px;
	width: auto;
}

.curso__header___cursos {
	display: flex;
	justify-content: center;
	border-right: 1px solid black;
	border-left: 1px solid black;
	width: 20%;
	text-transform: uppercase;
	height: 100%;
	align-items: center;
}

.curso__header___cursos:hover {
	border-right: 1.5px solid black;
	border-left: 1.5px solid black;
	background-color: #00449d;
	color: #fff;
	transition: all 120ms ease-in-out;
}

.curso__header___menu {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 10%;
	height: 100%;
}

.curso__header___menu:hover {
	background-color: #00449d;
	transition: all 120ms ease-in-out;
}

.curso__header___menu:hover .botao-menu {
	filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(90deg)
		brightness(99%) contrast(105%);
	transition: all 120ms ease-in-out;
}

.compra {
	background-color: #fff;
	text-align: left;
	border-radius: 25px;
	width: 80%;
	height: auto;
	margin: 70px 5%;
	padding: 2% 5%;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
	font-size: 200;
}

.curso {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px black solid;
	align-items: center;
}

.descricao {
	text-align: left;
	border-bottom: 1px black solid;
}

.descricao__text {
	text-transform: none;
}

.taxa {
	display: flex;
	justify-content: space-around;
	border-bottom: 1px black solid;
}

.investimento {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.formulario {
	font-family: "Montserrat", sans-serif;
	display: flex;
	flex-direction: column;
	text-align: left;
	align-items: center;
	justify-content: center;
	margin: 50px 0;
	padding: 0 5%;
}

.linha {
	margin-bottom: 80px;
}

.form-text {
	text-transform: uppercase;
	font-weight: bold;
	color: #fff;
	width: 100%;
}

.senhas,
.crm-cpf,
.telefones {
	display: flex;
	margin-top: 25px;
	width: 100%;
	justify-content: space-between;
}

.telefones {
	flex-wrap: wrap;
}

.senhas {
	gap: 15px;
}

.form__tipo,
.form__tel,
.form__CNA {
	flex: 1 0 21% !important;
}

.form__birth {
	flex: 1 0 35% !important;
}

.form__nome--completo,
.form__cep,
.form__city,
.form__state,
.form__street,
.form__CNA,
.form__number,
.form__email,
.form__senha,
.form__conf--senha,
.form__cpf,
.form__tipo,
.form__tel,
.form__leitura0,
.form__leitura1,
.form__btn {
	background: none;
	outline: none;
	border: 0;
	border-bottom: 2px solid #fff;
	color: #fff;
	height: 30px;
	font-weight: 200;
	font-size: 16px;
	padding: 0 0 5px 7px;
	appearance: none;
}

.form__nome--completo::placeholder,
.form__cep::placeholder,
.form__city::placeholder,
.form__state::placeholder,
.form__street::placeholder,
.form__CNA::placeholder,
.form__number::placeholder,
.form__email::placeholder,
.form__senha::placeholder,
.form__conf--senha::placeholder,
.form__crm::placeholder,
.form__cpf::placeholder,
.form__tipo::placeholder,
.form__tel::placeholder,
.form__leitura0::placeholder,
.form__leitura1::placeholder,
.form__btn::placeholder {
	color: #fff;
}

.form__nome--completo,
.form__email {
	margin-top: 25px;
	width: 99%;
	flex: 1;
}

.form__senha,
.form__conf--senha {
	width: 19.7vw;
	flex: 1;
}

.form__cpf {
	flex: 1;
}

.form__crm {
	display: flex;
	align-items: center;
}

.form__crm___label {
	color: #fff;
}

.form__tel {
	flex: 1;
}

.form__birth {
	flex: 1;
	width: 10vw;
	color-scheme: dark;
}

.form__CNA {
	flex: 1;
}

.telefones {
	gap: 15px;
}

.field-leitura {
	color: #fff;
	width: 100%;
	margin-top: 30px;
}

.square__checked input[type="checkbox"] {
	appearance: none;
	color: #fff;
	width: 1.15em;
	height: 1.15em;
	border: 0.15em solid #fff;
	border-radius: 0.15em;
	transform: translateY(-0.075em);
	display: grid;
	place-content: center;
	cursor: pointer;
}

.square__checked input[type="checkbox"]::before {
	content: "";
	width: 0.5em;
	height: 0.5em;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em #00a7ed;
	border-radius: 1.5px;
}

.square__checked input[type="checkbox"]:checked::before {
	transform: scale(1);
}

.leitura__text {
	text-decoration: none;
	color: #00a7ed;
}

.leituras input[type="checkbox"]:focus {
	outline: 0;
}
.leituras input[type="checkbox"] {
	appearance: none;
	background-color: #00449d;
	border: 1px solid #fff;
	border-radius: 26px;
	cursor: pointer;
	height: 18px;
	position: relative;
	transition: border 0.25s 0.15s, box-shadow 0.25s 0.3s, padding 0.25s;
	min-width: 35px;
	vertical-align: top;
	-webkit-appearance: none;
}
.leituras input[type="checkbox"]:after {
	background-color: #00a7ed;
	border: 1px solid #00a7ed;
	border-radius: 24px;
	box-shadow: inset 0 -3px 3px rgba(0, 0, 0, 0.025),
		0 1px 4px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.1);
	content: "";
	display: block;
	height: 14px;
	left: 0;
	position: absolute;
	right: 16px;
	top: 0;
	transition: border 0.25s 0.15s, left 0.25s 0.1s, right 0.15s 0.175s;
}
.leituras input[type="checkbox"]:checked {
	border-color: #fff;
	box-shadow: inset 0 0 0 13px #00a7ed;
	padding-left: 18px;
	transition: border 0.25s, box-shadow 0.25s, padding 0.25s 0.15s;
}
.leituras input[type="checkbox"]:checked:after {
	background-color: #00449d;
	border-color: #00449d;
	left: 16px;
	right: 0;
	transition: border 0.25s, left 0.15s 0.25s, right 0.25s 0.175s;
}

.form__tipo {
	appearance: none;
	height: 37px;
}

.field-btn {
	width: 100%;
	margin-top: 40px;
}

.form__btn:hover {
	background-color: #fff;
	color: #00449d;
	transition: all 120ms ease-in-out;
	box-shadow: 1px 1px 7px black;
}

.form__btn:hover .form__btn___img {
	filter: invert(13%) sepia(79%) saturate(3555%) hue-rotate(205deg)
		brightness(103%) contrast(104%);
	transition: all 120ms ease-in-out;
}

.form__btn {
	display: flex;
	align-items: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 18px;
	border: 2px white solid;
	border-radius: 10px;
	padding: 20px;
	cursor: pointer;
	font-family: "Montserrat", sans-serif;
}

.label--geral {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.social_box:hover {
	background-color: #00449d;
	border-color: #00449d;
	box-shadow: 1px 1px 7px black;
	transition: all 120ms ease-in-out;
}

.modal__button-close {
	background: none;
	border: 0;
	margin-top: 50px;
}

.modal__button-close:hover {
	cursor: pointer;
	scale: 110%;
}

.modal__button-open {
	font-family: "Montserrat", sans-serif;
	text-transform: uppercase;
	font-size: 1.05em;
	font-weight: bold;
	background: none;
	border: none;
}

.modal__button-open:hover {
	cursor: pointer;
	text-decoration: underline;
}

select {
	background: #00449d !important;
}

@media only screen and (max-width: 1100px) and (min-width: 770px) {
	.curso__header {
		width: 100%;
	}

	.curso__header___select {
		width: 100%;
	}

	.formulario {
		max-width: 400px;
	}

	.linha {
		margin-bottom: 20px;
	}

	.linha img {
		max-width: 400px;
	}

	.telefones {
		flex-wrap: wrap;
	}

	.form__tel {
		margin: 5px 0 0 0;
	}

	.label--geral {
		flex-wrap: wrap;
	}

	.social_box {
		max-width: 15%;
	}
}

@media only screen and (max-width: 769px) {
	#item-0 {
		grid-area: header-cursos;
	}
	#item-1 {
		grid-area: pagamento;
	}
	#item-2 {
		grid-area: login;
	}
	#item-3 {
		grid-area: header-logo;
		padding-right: 20px;
	}
	.grid {
		grid-template:
			"header-logo header-cursos"
			"login login"
			"pagamento pagamento";

		height: auto;
		grid-template-columns: 3fr 5fr;
	}

	.curso__header___select {
		display: none;
	}

	.curso__header___cursos {
		width: 50%;
		border-left: 0;
	}
	.curso__header___cursos:hover {
		border-right: 0;
		border-left: 0;
	}

	.curso__header___menu {
		width: 50%;
	}

	.linha {
		margin-top: 50px;
		width: 100%;
	}

	.linha img {
		width: 100%;
	}

	.formulario {
		width: 90%;
	}

	.form__nome--completo,
	.form__email,
	.senhas,
	.crm-cpf,
	.telefones {
		width: 100%;
		flex-wrap: wrap;
	}

	.senhas {
		justify-content: space-between;
	}

	.form__cpf,
	.form__tel {
		width: 75%;
	}

	.form__crm {
		width: 25%;
	}

	.field-btn {
		margin-bottom: 50px;
	}

	.login-selected {
		padding-bottom: 1100px;
	}
}

@media only screen and (max-width: 480px) {
	* {
		font-size: 99.5%;
	}

	.grid {
		grid-template-columns: 2fr 3fr;
	}
	.curso p {
		text-align: end;
	}

	.curso__header___menu {
		width: 30%;
	}

	.curso__header___cursos {
		width: 70%;
	}

	.compra {
		margin: 30px 5%;
	}

	.linha {
		width: 100%;
		scale: 90%;
		margin-bottom: 20px;
	}

	.linha img {
		width: 100%;
	}

	.formulario {
		width: 90%;
		margin: 0 auto;
	}

	.telefones {
		flex-wrap: wrap;
	}

	.form__tipo {
		width: auto;
	}

	.form__tel {
		margin: 5px 0 0 0;
	}

	.form__crm {
		width: auto;
	}

	.form__cpf {
		width: auto;
		margin: 0;
	}

	.label--geral {
		flex-wrap: wrap;
		font-size: 12px;
	}

	.social a:not(:first-of-type) {
		scale: 100%;
	}

	.social_box:not(:first-of-type) {
		margin-left: 5px;
	}
}
</style>
