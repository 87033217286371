import axios from "axios"
import cookieService from "@/services/Cookie.service"

//const URL = "https://api-sso.gruposantajoana.com.br/api/v1/"
export const URL = "https://api-sso.gruposantajoana.com.br/api/v1/"
export const URLCOOKIE = "sso.gruposantajoana.com.br"

export const axiosInstance = (isBlob = false) => {
	return axios.create({
		baseURL: URL,
		headers: {
			common: {
				"Access-Control-Allow-Origin": "*"
			},
			"Cache-Control": "no-cache",
			Authorization: `Bearer ${cookieService.getCookie("token") ?? ""}`,
			Pragma: "no-cache",
			Expires: "0"
		},
		responseType: isBlob ? "blob" : null,
		validateStatus: (status) => {
			return status < 400
		}
	})
}
