import { exceptions } from "@/api/exceptions"
import store from "@/store"

import userService from "@/services/User.service"
import cookieService from "@/services/Cookie.service"
import { hideLoading, showLoading } from "@/utils/loading"

class UserController {
	async auth(data) {
		try {
			showLoading()

			const response = await userService.auth(data)

			hideLoading()

			if (response !== null) {
				if (response.status === 200) {
					return response.data
				} else {
					alert("Erro ao buscar")
				}
			}
		} catch (error) {
			alert(exceptions(error))
			hideLoading()
		}

		return null
	}

	async register(data) {
		try {
			const response = await userService.register(data)

			if (response !== null) {
				if (response.status === 201) {
					return response.data
				} else {
					alert("Erro ao salvar")
				}
			}
		} catch (error) {
			alert(exceptions(error))
		}

		return null
	}

	async myProfile() {
		try {
			const response = await userService.myProfile({
				token: cookieService.getCookie("token")
			})

			if (response !== null) {
				if (response.status === 200) {
					return response.data
				} else {
					alert("Erro ao buscar")
				}
			}
		} catch (error) {
			alert(exceptions(error))
		}

		return null
	}

	async logout() {
		cookieService.deleteCookie("token")
		cookieService.deleteCookie("user")

		store.dispatch("setUser", {})
		store.dispatch("setToken", null)
	}

	async requestPasswordReset(data) {
		try {
			const response = await userService.requestPasswordReset(data)

			if (response !== null) {
				if (response.status === 200) {
					return response.data
				} else {
					alert("Erro ao resetar senha")
				}
			}
		} catch (error) {
			alert(exceptions(error))
		}

		return null
	}

	async getProfileProducts() {
		try {
			const response = await userService.getProfileProducts()

			if (response !== null) {
				if (response.status === 200) {
					return response.data
				} else {
					alert("Erro ao buscar")
				}
			}
		} catch (error) {
			alert(exceptions(error))
		}

		return null
	}

	async passwordReset(data) {
		try {
			const response = await userService.passwordReset(data)

			if (response !== null) {
				if (response.status === 200) {
					return response.data
				} else {
					alert("Erro ao resetar senha")
				}
			}
		} catch (error) {
			alert(exceptions(error))
		}

		return null
	}
}

export default new UserController()
