<template>
	<section>
		<link rel="preconnect" href="https://fonts.googleapis.com" /><link
			rel="preconnect"
			href="https://fonts.gstatic.com"
			crossorigin
		/><link
			href="https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;700&display=swap"
			rel="stylesheet"
		/>
		<div class="grid">
			<div id="item-0">
				<div class="curso__header___menu" @click="openMenu">
					<a class="botao-menu">
						<div class="menu_image"></div>

						<!-- <img alt="" class="menu_image" /> -->
					</a>
				</div>
			</div>
			<div id="item-1">
				<div class="dados">
					<div class="dados__titulo">
						<h2>dados pessoais</h2>
					</div>

					<img src="@/assets/person.svg" alt="" />
					<h1 class="dados__nome">
						{{ this.$store.state.user.name }}
					</h1>
					<h3 class="dados__email">
						{{ this.$store.state.user.email }}
					</h3>
					<h3 class="dados__telefone">
						{{ this.$store.getters["getPhone"] }}
					</h3>
				</div>
			</div>
			<div id="item-2">
				<div class="minha-conta__botoes">
					<button
						class="minha-conta__botao"
						:class="card === 'dados' ? '--ativo' : ''"
						@click="card = 'dados'"
					>
						Minha Conta
					</button>

					<button
						class="minha-conta__botao"
						:class="card === 'cursos' ? '--ativo' : ''"
						@click="card = 'cursos'"
					>
						Meus Cursos
					</button>
				</div>

				<DadosWidget v-if="card == 'dados'" />
				<CursosWidget v-if="card == 'cursos'" />
			</div>
			<div id="item-3">
				<a href="https://gruposantajoana.com.br/ensinoepesquisa/"
					><img src="@/assets\Logo_gsj.png" alt="" class="logo"
				/></a>
			</div>
		</div>
		<div class="separador">
			<div class="fale-conosco">
				<a href="#"
					><h2>
						Fale Conosco
						<img src="@/assets/seta-baixo-black.svg" alt="" /></h2
				></a>
				<div class="social">
					<h2>Social</h2>
					<a href="#"><img src="@/assets/Face.svg" alt="" /></a>
					<a href="#"><img src="@/assets/Insta.svg" alt="" /></a>
					<a href="#"><img src="@/assets/Youtube.svg" alt="" /></a>
					<a href="#"><img src="@/assets/Linkedin.svg" alt="" /></a>
				</div>
				<a href="#"
					><h2>
						Política de Privacidade
						<img src="@/assets/seta-baixo-black.svg" alt="" /></h2
				></a>
			</div>
		</div>
		<div class="footer">
			<textfield class="footer" readonly>
				<p><b>© 2020 Santa Joana. Todos os direitos reservados</b></p>
				<p>
					Rua do Paraíso, 432 | CEP 04103-000 | Paraíso | São Paulo |
					SP | 11 5080 6000
				</p>
				<p>Responsável Técnico: Dr.Eduardo Rahme Amaro | CRM 31624</p>
			</textfield>
		</div>
	</section>
	<MenuWidget ref="menu" />
</template>

<script>
import MenuWidget from "@/components/MenuWidget.vue"
import CursosWidget from "../components/CursosWidget.vue"
import DadosWidget from "../components/DadosWidget.vue"

export default {
	components: {
		DadosWidget,
		CursosWidget,
		MenuWidget
	},
	methods: {
		openMenu() {
			this.$refs.menu.openMenu()
		}
	},
	data() {
		return {
			card: "dados"
		}
	}
}
</script>

<style scoped>
.grid {
	display: grid;

	grid-template: 17vh / 40% 60%;

	height: 100vh;
}

#item-0 {
	background-color: #fff;
	grid-row: 1 / 2;
	grid-column: 2 / 3;
	display: flex;
	flex-direction: row-reverse;
}
#item-1 {
	background-color: #00a7ed;
	grid-row: 2 / 4;
	grid-column: 1 / 2;
	display: flex;
	justify-content: center;
	align-items: baseline;
}

#item-2 {
	background-color: #00449d;
	grid-row: 2 / 4;
	grid-column: 2 / 3;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start !important;
	padding-top: 30px;
	width: 100%;
}

#item-3 {
	background-color: #fff;
	grid-row: 1 / 2;
	grid-column: 1 / 2;
	text-align: left;
	padding: 2.5% 2.5%;
}

.logo {
	margin-left: 10%;
}

.dados {
	margin-top: 65px;
}

.curso__header {
	justify-content: flex-end !important;
}

.curso__header___select {
	display: flex;
	justify-content: center;
	width: 50%;
	text-transform: uppercase;
	height: 100%;
	align-items: center;
	border-left: 1px black solid;
}

.curso__header___select h2 {
	font-weight: 400;
}

.curso__header___cursos {
	display: flex;
	justify-content: center;
	border-right: 1px solid black;
	border-left: 1px solid black;
	width: 30%;
	text-transform: uppercase;
	height: 100%;
	align-items: center;
}
.curso__header___cursos h2 {
	font-weight: 400;
}
.curso__header___cursos:hover {
	border-right: 1.5px solid black;
	border-left: 1.5px solid black;
	background-color: #00449d;
	color: #fff;
	cursor: pointer;
}
.curso__header___cursos a {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: black;
}

.curso__header___cursos a:hover {
	color: white;
}
.curso__header___menu {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20%;
	height: 100%;
}

.curso__header___menu:hover {
	background-color: #00449d;
	cursor: pointer;
}

.curso__header___menu:hover .botao-menu {
	filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(90deg)
		brightness(99%) contrast(105%);
}

.social h2 {
	margin: 0;
}

.social a:not(:first-of-type) {
	margin-left: 20px;
	scale: 120%;
}

@media only screen and (max-width: 769px) {
	#item-0 {
		grid-area: header-cursos;
	}
	#item-1 {
		grid-area: pagamento;
	}
	#item-2 {
		grid-area: login;
	}
	#item-3 {
		grid-area: header-logo;
	}
	.grid {
		grid-template:
			"header-logo header-cursos"
			"login login"
			"pagamento pagamento";

		height: auto;
		grid-template-columns: 2fr 5fr;
	}

	#item-1 {
		display: none;
	}

	#item-2 {
		padding: 50px 0;
	}

	.minha-conta {
		height: auto;
		width: 80%;
	}

	.minha-conta__botoes {
		width: 80%;
	}
	.logo {
		width: 90%;
	}
	.curso__header {
		width: 65%;
	}
	.curso__header___select {
		display: none;
	}

	.curso__header___cursos {
		width: 50%;
		border-left: 1px solid black;
	}

	.curso__header___cursos:hover {
		border-right: 0;
		border-left: 0;
	}

	.curso__header___menu {
		width: 50%;
	}

	.minha-conta__nome,
	.minha-conta__email,
	.minha-conta__senha,
	.minha-conta__cpf,
	.minha-conta__crm,
	.minha-conta__telefone {
		margin: 7px 0;
	}
}

@media only screen and (max-width: 480px) {
	* {
		font-size: 99%;
	}

	.minha-conta {
		width: 90%;
	}

	.minha-conta__botoes {
		width: 90%;
	}
	.minha-conta__botao {
		font-size: 10px;
	}
	.grid {
		grid-template-columns: 2fr 3fr;
	}

	.curso__header {
		width: 90%;
	}
	.botao-menu img {
		width: 70%;
	}
	.curso__header___menu {
		width: 30%;
	}

	.curso__header___cursos {
		width: 70%;
	}

	.social a:not(:first-of-type) {
		margin-left: 10px;
		scale: 100%;
	}
}
</style>
