<template>
	<div class="page">
		<header>
			<img src="@/assets/Logo_gsj_grande.png" alt="" />
		</header>

		<main class="content-page">
			<h2>
				TERMOS E CONDIÇÕES DE USO DA PLATAFORMA DE EVENTOS DO GRUPO
				SANTA JOANA
			</h2>
			<small>Data da Última Atualização: 04 de março de 2024</small>
			<p>
				O Grupo Santa Joana (“nós”) apresenta, a seguir, os Termos e
				Condições de Uso de sua Plataforma de Eventos, documento que
				relaciona as principais regras que devem ser observadas por
				Você, usuário, ao utilizar a Plataforma de Eventos
				disponibilizada
			</p>
			<p>
				Como condição para acesso e uso das funcionalidades da
				Plataforma de Eventos do Grupo Santa Joana, você declara que fez
				a leitura completa e atenta das regras deste documento, estando
				plenamente ciente e de acordo com elas.
			</p>
			<p>
				<strong
					>Objetivo e Funcionalidades da Plataforma de Eventos do
					Grupo Santa Joana, Cadastro e Acesso</strong
				>
			</p>

			<h3>1. Objetivo:</h3>
			<p>
				A plataforma tem como objetivo apoiar a realização e gestão dos
				eventos realizados pelo Grupo Santa Joana.
			</p>

			<h3>2. Funcionalidades:</h3>
			<ul>
				<li>
					<strong>Realizar o cadastro dos usuários:</strong>
					Interessados em determinado evento organizado pelo Grupo
					Santa Joana.
				</li>
				<li>
					<strong>Coletar consentimentos necessários:</strong> Para o
					tratamento dos dados seguindo a Política de Privacidade do
					Grupo Santa Joana.
				</li>
				<li>
					<strong>Disponibilizar área restrita e segura:</strong> Para
					a realização do pagamento do evento selecionado.
				</li>
				<li>
					<strong
						>Oferecer recursos para criação de relatórios detalhados
						pós-evento.</strong
					>
				</li>
			</ul>

			<h4>
				2.1. A contratação de serviços utilizando-se do conforto e da
				praticidade do autoatendimento, para a realização do cadastro e
				do pagamento online.
			</h4>

			<h4>2.2. Dados mantidos em cadastro:</h4>
			<p>
				<strong>Pessoa Física:</strong> nome completo, e-mail, CPF, CRM,
				celular, data de nascimento, pontuação CNA, endereço completo e
				informações para pagamento cartão de crédito.
			</p>

			<h4>2.3. Não são admitidos cadastros de menores de 18 anos.</h4>

			<h4>2.4. Compromisso de veracidade das informações cadastrais.</h4>

			<h3>3. Isenção e Limitação de Responsabilidade:</h3>
			<p>
				O Grupo Santa Joana empenha seus esforços para a manutenção da
				disponibilidade contínua e permanente da Plataforma de Eventos.
				No entanto, pode ocorrer, eventualmente, alguma
				indisponibilidade temporária decorrente de manutenção necessária
				ou mesmo gerada por motivo de força maior, como desastres
				naturais, falhas nos sistemas de comunicação e acesso à
				Internet, ataques cibernético invasivos, ou quaisquer fatos de
				terceiro que fogem da esfera de vigilância e responsabilidade do
				grupo.
			</p>

			<p>
				O Grupo Santa Joana não se responsabiliza por quaisquer atrasos
				e/ou pendências na disponibilização de dados pela utilização do
				serviço objeto desse termo, quando esses decorrerem de falhas
				e/ou erros oriundos do próprio usuário ou de terceiros, ou ainda
				se advierem de circunstâncias caracterizadas como caso fortuito
				ou de força maior.
			</p>

			<p>
				O usuário declara-se ciente, desde já, da inexistência de
				segurança infalível em programas de computadores e exime o Grupo
				Santa Joana da responsabilidade resultante de perdas decorrentes
				de eventual parada em quaisquer programas de seus computadores,
				incluindo aqui a responsabilidade civil em geral e, em especial,
				possíveis perdas caracterizadas como lucros cessantes.
			</p>

			<p>
				O Grupo Santa Joana poderá cancelar ou limitar a utilização da
				Plataforma de Eventos, se for constatado que houve utilização de
				forma fraudulenta, bem como que tentativa ou violação destes
				Termos ou quaisquer legislações vigentes.
			</p>

			<h3>4. Atualizações:</h3>
			<p>
				Estes Termos estão sujeitos a constante melhoria e
				aprimoramento. Assim, o Grupo Santa Joana se reserva o direito
				de modificá-los a qualquer momento, conforme sua finalidade ou
				conveniência, como também para adequação e conformidade legal de
				disposição de lei ou norma que tenha força jurídica equivalente,
				cabendo ao Usuário verificá-los sempre que efetuar o acesso na
				Plataforma.
			</p>
		</main>
	</div>
</template>

<script>
export default {}
</script>

<style scoped>
.content-page {
	max-width: 80%;
	margin: 0 auto;
	text-align: justify;
	padding: 10px;
	font-size: 14px;
}
.content-page h2 {
	font-size: 20px;
	text-align: center;
}
.content-page h3 {
	font-size: 16px;
}
.content-page small {
	font-size: 14px;
}
</style>
