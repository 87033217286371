import { axiosInstance } from "@/api/connection"

class UserService {
	async auth(data) {
		const instance = axiosInstance()
		return instance.post("auth", data)
	}

	async register(data) {
		const instance = axiosInstance()
		return instance.post("public/user", data)
	}

	//my profile
	async myProfile() {
		const instance = axiosInstance()
		return instance.get("myprofile")
	}

	//request-password-reset
	async requestPasswordReset(data) {
		const instance = axiosInstance()
		return instance.post("sso/request-password-reset", data)
	}

	//get-profile-products
	async getProfileProducts() {
		const instance = axiosInstance()
		return instance.get("get-profile-products")
	}

	async passwordReset(data) {
		const instance = axiosInstance()
		return instance.post("sso/password-reset", data)
	}
}

export default new UserService()
